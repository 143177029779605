import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss']
})
export class FilterBarComponent implements OnInit {
  @Output() applyFilters = new EventEmitter<any>();

  selectedCountry: string = '';
  selectedCourse: string = '';
  selectedStudyLevel: string = '';

  countries: string[] = ['Germany', 'US', 'UK'];
  courses: string[] = [
    'Computer Science', 'Data Science', 'Engineering', 'Information Technology',
    'Cybersecurity', 'Mechanical Engineering', 'Electrical Engineering', 'Civil Engineering',
    'Business Administration', 'Finance', 'Economics', 'Mathematics', 'Physics', 'Chemistry',
    'Biology', 'Environmental Science', 'Biomedical Engineering', 'Artificial Intelligence',
    'Software Engineering', 'Statistics'
  ];

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.loadStudentChoices();
  }

  onApplyFilters() {
    const filterData = {
      country: this.selectedCountry,
      course: this.selectedCourse,
      studyLevel: this.selectedStudyLevel
    };
    console.log('Emitting filterData:', filterData); 
    this.applyFilters.emit(filterData);  
  }

  private loadSavedFilters() {
    const savedFilters = localStorage.getItem('savedFilters');
    if (savedFilters) {
      const parsedFilters = JSON.parse(savedFilters);
      this.selectedCountry = parsedFilters.country;
      this.selectedCourse = parsedFilters.course;
      this.selectedStudyLevel = parsedFilters.studyLevel;
    }
  }

  private loadStudentChoices() {
    const cachedChoices = localStorage.getItem('studentChoices');
    
    // If cached data exists, load it
    if (cachedChoices) {
      const parsedChoices = JSON.parse(cachedChoices);
      this.selectedCountry = parsedChoices.country;
      this.selectedCourse = parsedChoices.course;
      this.selectedStudyLevel = parsedChoices.studyLevel;
  
      console.log('Loaded cached choices:', parsedChoices);
  
      const filterData = {
        country: this.selectedCountry,
        course: this.selectedCourse,
        studyLevel: this.selectedStudyLevel
      };
  
      this.applyFilters.emit(filterData);
  
    } else {
      // If no cached data, call the API and cache the result
      const token = localStorage.getItem('access_token');
      const uid = localStorage.getItem('uid');
  
      if (!uid) {
        console.error('UID is missing from localStorage');
        return;
      }
  
      const headers = new HttpHeaders({
        'Authorization': `Bearer ${token}`,
        'uid': uid
      });
  
      this.http.get('http://localhost:5001/student-choices/get-student-choices/', { headers }).subscribe(
        (response: any) => {
          console.log('Response received from API:', response.data);
  
          this.selectedCountry = response.data.country;
          this.selectedCourse = response.data.course;
          this.selectedStudyLevel = response.data.degree;
  
          // Cache the API response in localStorage
          localStorage.setItem('studentChoices', JSON.stringify(response.data));
  
          const filterData = {
            country: this.selectedCountry,
            course: this.selectedCourse,
            studyLevel: this.selectedStudyLevel
          };
  
          console.log('Emitting filterData:', filterData);
          this.applyFilters.emit(filterData);
        },
        (error) => {
          console.error('Error fetching student choices:', error);
        }
      );
    }
  }
  
}

