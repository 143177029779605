import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';

@Component({
  selector: 'app-profile-helper',
  templateUrl: './profile-helper.component.html',
  styleUrls: ['./profile-helper.component.scss']
})
export class ProfileHelperComponent implements OnInit {
  selectedFile: File | null = null; // Holds the selected file
  uploadStatus: string = ''; // For feedback on the upload process

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }

  // Triggered when the user selects a file
  onFileSelected(event: any): void {
    const file = event.target.files[0];
    if (file && file.type === 'application/pdf') {
      this.selectedFile = file;
      this.uploadStatus = `Selected file: ${file.name}`;
    } else {
      this.uploadStatus = 'Please select a valid PDF file.';
    }
  }

   // Triggered when the user clicks the "Upload" button
  uploadFile(): void {
    if (!this.selectedFile) {
      this.uploadStatus = 'No file selected.';
      return;
    }

    const uid = localStorage.getItem('uid'); // Retrieve UID from localStorage
    if (!uid) {
      this.uploadStatus = 'User ID not found. Please log in first.';
      return;
    }

    const headers = new HttpHeaders({
      uid: uid
    });

    const formData = new FormData();
    formData.append('file', this.selectedFile);

    // Backend API endpoint for uploading the PDF
    this.http.post('/api/upload', formData, { headers }).subscribe(
      (response) => {
        this.uploadStatus = 'File uploaded successfully!';
        console.log('Response from server:', response);
      },
      (error) => {
        this.uploadStatus = 'File upload failed. Please try again.';
        console.error('Error:', error);
      }
    );
  }
}
