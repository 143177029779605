import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataCacheService {
  // Save data in local storage
  setCachedData(key: string, data: any): void {
    localStorage.setItem(key, JSON.stringify(data));
  }

  // Retrieve cached data from local storage
  getCachedData(key: string): any {
    const cachedData = localStorage.getItem(key);
    return cachedData ? JSON.parse(cachedData) : null;
  }

  // Clear specific cached data
  clearCache(key: string): void {
    localStorage.removeItem(key);
  }

  // Clear all cached data (if needed)
  clearAllCache(): void {
    localStorage.clear();
  }
}
