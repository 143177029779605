import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class WishlistService {
  private apiUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient) { }

  addToWishlist(course: any): Observable<any> {
    const token = localStorage.getItem('access_token'); 
    const uid = localStorage.getItem('uid'); 
    
    if (!token) {
      console.error('No JWT token found in local storage');
      return throwError('No JWT token found in local storage');
    }
    
    if (!uid) {
      console.error('No UID found in local storage');
      return throwError(() => new Error('No UID found in local storage'));
    }

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'uid': uid  
    });

    const payload = {
      courseId: course.course_id, 
      courseName: course.course_name, 
      universityName: course.university, 
      tuitionFees: course.yearly_tuition_fees, 
      courseDuration: course.duration, 
      degree: course.study_level,
      intakeSeason: course.open_intakes, 
      applicationFees: course.application_fee, 
      location: course.campus,
      country: course.country
    };
    console.log(payload)
    return this.http.post(`${this.apiUrl}/student-choices/add-to-wishlist`, payload, { headers });
  }

  removeFromWishlist(courseId: string, headers: HttpHeaders): Observable<any> {
    console.log(courseId);
    return this.http.delete(`${this.apiUrl}/student-choices/remove-from-wishlist/${courseId}`, { headers });
  }

  getWishlist(headers?: HttpHeaders): Observable<any> {
    return this.http.get(`${this.apiUrl}/student-choices/get-from-wishlist/`, { headers }).pipe(
        map((response: any) => {
            // Calculate the length of the wishlist array and return it along with the response
            if (response && response.data) {
                const wishlistLength = response.data.length;
                return { wishlistLength, data: response.data };
            } else {
                return { wishlistLength: 0, data: [] };
            }
        }),
        catchError((error: any) => {
            console.error('Error fetching wishlist:', error);
            return of({ wishlistLength: 0, data: [] }); // Return an observable with default values in case of an error
        })
    );
}
 
}
