<div class="flex min-h-screen bg-gray-100">
  <!-- Sidebar -->
  <div class="sidebar w-1/2 flex flex-col items-center justify-center">
    <img src="https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1729246686447Illustrations%201.png" alt="Education Icon" class="w-[240px] h-[240px] mb-6"> <!-- Placeholder image -->
    <h1 class="text-[#00558C] font-semibold text-3xl mb-4 font-poppins">Start Your Next Learning</h1>
    <h1 class="text-[#00558C] font-semibold text-3xl mb-4 font-poppins">Adventure!</h1>
  </div>

  <!-- Main Content -->
  <div class="w-1/2 bg-white p-10">


<!-- Header Container with Arrow and Heading -->
  <div class="header-container flex items-center gap-2">
    <!-- Back Button -->
    <button 
      *ngIf="step !== 'studyDetails'" 
      (click)="prevStep()" 
      class="back-button"
    >
      <img 
        src="assets/icons/arrow-left.svg" 
        alt="Previous Step" 
        class="arrow-icon"
      />
    </button>

    <!-- Step Header -->
    <h2 class="section-heading font-poppins mt-6 ml-2">
      <span *ngIf="step === 'studyDetails'">Your Target Destination & Degree</span>
      <span *ngIf="step === 'undergraduateDetails'">Your Undergraduation Score</span>
      <span *ngIf="step === 'highSchoolDetails'">Your High School Score</span>
      <span *ngIf="step === 'testScores'">Your Test Scores</span>
      <span *ngIf="step === 'professionDetails'">Your Work Experience</span> 
      <span *ngIf="step === 'registration'">Registration</span>
      <span *ngIf="step === 'otpVerification'">OTP</span>
    </h2>
  </div>

     <!-- Custom Button Selection -->
     <div *ngIf="step === 'studyDetails'" class="step-container-firstpage">
      <!-- Target Destination -->
      <div class="target-section">
        <label class="target-label font-poppins">Target Destination</label>
        <div class="button-group">
          <button *ngFor="let country of countryOptions" 
                  class="target-button" 
                  [ngClass]="{'active': finalPayload.targetCountry === country.name}" 
                  (click)="selectCountry(country)">
            <img [src]="country.flagUrl" alt="{{ country.name }} Flag" class="w-[30px] h-[14px] mr-2">
            {{ country.name }}
          </button>
        </div>
        <div *ngIf="showError && !finalPayload.targetCountry" class="text-red-500 text-sm mt-1 font-poppins">
          Please select a target destination
        </div>
      </div>

    
      <!-- Target Degree -->
      <div class="target-section">
        <label class="target-label font-poppins">Target Degree</label>
        <div class="button-group">
          <button *ngFor="let degree of degreeOptions" 
                  class="target-button" 
                  [ngClass]="{
                    'active': 
                    (degree === 'Bachelors' && finalPayload.targetDegree === 'Undergraduate') || 
                    (degree === 'Masters' && finalPayload.targetDegree === 'Postgraduate')
                  }"
                  (click)="selectDegree(degree)">
            {{ degree }}
          </button>
        </div>
        <div *ngIf="showError && !finalPayload.targetDegree" class="text-red-500 text-sm mt-1 font-poppins">
          Please select a target degree
        </div>
      </div>

      <!-- Course Specialization -->
      <div class="target-section">
        <label class="target-label font-poppins">Course Specialization</label>
      
        <!-- Dropdown Toggle (Displays Selected Course) -->
        <div 
          (click)="toggleCourseDropdown()" 
          class="course-dropdown-toggle p-2 border border-gray-300 cursor-pointer mb-1 rounded-lg"
        >
          {{ finalPayload.targetCourse || 'Select a Course' }}
        </div>
      
        <!-- Custom Dropdown Menu with Search Input (only visible when isCourseDropdownOpen is true) -->
        <div *ngIf="isCourseDropdownOpen" class="absolute bg-white border border-gray-300 mt-2 w-full max-h-60 overflow-y-auto z-10">
          <!-- Search Input -->
          <input
            type="text"
            [(ngModel)]="courseSearchTerm"
            placeholder="Search for your preferred course"
            class="w-full p-2 border-b border-gray-300 outline-none text-[14px] font-poppins"
          />
      
          <!-- Filtered Options -->
          <div *ngFor="let course of filteredCourses()">
            <div
              (click)="selectCourse(course)"
              class="p-2 cursor-pointer hover:bg-gray-100"
            >
              {{ course }}
            </div>
          </div>
        </div>
      
        <!-- Error Message -->
        <div *ngIf="showError && !finalPayload.targetCourse" class="text-red-500 text-sm mt-1 font-poppins">
          Please select a course specialization
        </div>
      
        <!-- Button Group for Quick Selection -->
        <div class="button-group course-group">
          <button 
            *ngFor="let course of courseOptions" 
            class="target-button" 
            [ngClass]="{'active': finalPayload.targetCourse === course}" 
            (click)="selectCourse(course)"
          >
            {{ course }}
          </button>
        </div>
      </div>
      
      
    </div>

    
    <!-- Test Scores -->
    <div *ngIf="step === 'testScores'" class="test-step-container">
      <!-- Aptitude Test -->
      <div>
        <label class="block text-lg font-semibold mb-2">Aptitude Test</label>
        <p class="text-[#00000080] font-semibold mb-1">Test Taken</p>
        <div class="flex space-x-4">
          <button
            *ngFor="let test of aptitudeTestOptions"
            class="px-4 py-2 rounded-full border"
            [ngClass]="{'bg-blue-500 text-white': finalPayload.aptitudeTest === test, 'border-gray-300': finalPayload.aptitudeTest !== test}"
            (click)="selectAptitudeTest(test)">
            {{ test }}
          </button>
        </div>
        <div *ngIf="showError && !finalPayload.aptitudeTest" class="text-red-500 text-sm mt-1">
          Please select an aptitude test
        </div>

        <!-- Score Input (Only show when a test is selected) -->
        <div *ngIf="finalPayload.aptitudeTest !== 'Not Taken Yet'" class="mt-4">
          <input
            type="text"
            [placeholder]="generatePlaceholder(finalPayload.aptitudeTest)"
            class="w-full p-2 border border-gray-300 rounded-lg"
            [(ngModel)]="finalPayload.aptitudeTestScore">

            <div *ngIf="showError && finalPayload.aptitudeTest !== 'Not Taken Yet' && !finalPayload.aptitudeTestScore" class="text-red-500 text-sm mt-1">
              Please enter your aptitude test score
            </div>
        </div>
      </div>

      <div class="border-t-2 border-dotted border-gray-400 mt-2"></div>

      <!-- Language Test -->
      <div>
        <label class="block text-lg font-semibold mb-2">Language Test</label>
        <p class="text-[#00000080] font-semibold mb-1">Test Taken</p>
        <div class="flex space-x-4">
          <button
            *ngFor="let test of languageTestOptions"
            class="px-4 py-2 rounded-full border"
            [ngClass]="{'bg-blue-500 text-white': finalPayload.languageTest === test, 'border-gray-300': finalPayload.languageTest !== test}"
            (click)="selectLanguageTest(test)">
            {{ test }}
          </button>
        </div>
        <div *ngIf="showError && !finalPayload.languageTest" class="text-red-500 text-sm mt-1">
          Please select a language test
        </div>

        <!-- Score Input (Only show when a test is selected) -->
        <div *ngIf="finalPayload.languageTest !== 'Not Taken Yet'" class="mt-4">
          <input
            type="text"
            [placeholder]="generatePlaceholder(finalPayload.languageTest)"
            class="w-full p-2 border border-gray-300 rounded-lg"
            [(ngModel)]="finalPayload.languageTestScore">
            <div *ngIf="showError && finalPayload.languageTest !== 'Not Taken Yet' && !finalPayload.languageTestScore" class="text-red-500 text-sm mt-1">
              Please enter your language test score
            </div>
        </div>
      </div>
    </div>


      <!-- Undergraduate Details Form -->
   

    
    <!-- Dynamic Formly Form -->
    <formly-form *ngIf="step !== 'studyDetails' && step !== 'testScores' && step !== 'otpVerification'" [form]="form" [model]="finalPayload" [fields]="userDetailsField" class="space-y-4"></formly-form>


    <div *ngIf="step === 'otpVerification'" class="custom-input-wrapper">
      <!-- <p>Enter the 6-digit OTP sent to your phone ****{{finalPayload.phoneNumber | slice:-4}}.</p> -->


          <div class="custom-input-otp">
            <input type="text" maxlength="1" [(ngModel)]="otpValues[0]" name="otp0" required 
                   class="otp-box" (keyup)="moveFocus(0)">
            <input type="text" maxlength="1" [(ngModel)]="otpValues[1]" name="otp1" required 
                   class="otp-box" (keyup)="moveFocus(1)">
            <input type="text" maxlength="1" [(ngModel)]="otpValues[2]" name="otp2" required 
                   class="otp-box" (keyup)="moveFocus(2)">
            <input type="text" maxlength="1" [(ngModel)]="otpValues[3]" name="otp3" required 
                   class="otp-box" (keyup)="moveFocus(3)">
            <input type="text" maxlength="1" [(ngModel)]="otpValues[4]" name="otp4" required 
                   class="otp-box" (keyup)="moveFocus(4)">
            <input type="text" maxlength="1" [(ngModel)]="otpValues[5]" name="otp5" required 
                   class="otp-box" (keyup)="moveFocus(5)">
          </div>
        

          <p class="text-gray-500 text-sm mt-4">
            Request new code in 
            <span *ngIf="countdown > 0" class="text-blue-500">00:{{ countdown }}</span>
            <button *ngIf="countdown === 0" class="text-blue-500 underline" (click)="resendOtp()">Resend OTP</button>
          </p>
    </div>
    
    <!-- Navigation Buttons -->
    <div class="button-container">
    <button class="continue-button" (click)="onSubmit()">
      {{ step === 'registration' ? 'Submit' : 'Continue' }}
      <!-- <img src="assets/icons/arrow-left.svg" alt="arrow" class="arrow-icon"> -->
    </button>    
  </div>
  </div>
</div>
