<div class="pdf-upload-container">
    <h2>Upload a PDF</h2>
  
    <!-- File Input -->
    <input type="file" accept="application/pdf" (change)="onFileSelected($event)" />
  
    <!-- Upload Button -->
    <button (click)="uploadFile()" [disabled]="!selectedFile">Upload PDF</button>
  
    <!-- Upload Status -->
    <p>{{ uploadStatus }}</p>
  </div>
  