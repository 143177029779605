  <div class="wishlist-container mx-auto mt-2">
  <div>
    <button (click)="prevStep()" class="text-gray-600 mb-4 mr-4 flex items-center">
      <img 
        src="assets/icons/arrow-left.svg" 
        alt="Back" 
        class="w-6 h-6"
      />
    </button>
  </div>  
  <div class="wishlist-header flex justify-between items-center mb-6">
    <h2 class="wishlist-title text-3xl font-bold">
      <span class="heart-icon">❤️</span> Your Shortlisted Universities
    </h2>
      <!-- Button and Dropdown Wrapper -->
    <div class="relative inline-block">
      <!-- Main Download Button -->
      <button (click)="toggleDownloadOptions()" class="download-button flex items-center relative">
        Download Shortlist
        <img src="assets/icons/download-arrow.svg" alt="Download Icon" class="icon w-6 h-6 ml-2" />
      </button>
      
      <!-- Dropdown for PDF and Excel Options -->
      <div *ngIf="isDownloadOptionsVisible" class="download-options absolute mt-2 bg-white border rounded shadow-lg p-2 right-0 ">
        <button (click)="downloadPDF(); toggleDownloadOptions()" class="block w-full text-left px-4 py-2 hover:bg-gray-100 font-poppins">
          Download as PDF
        </button>
        <button (click)="downloadExcel(); toggleDownloadOptions()" class="block w-full text-left px-4 py-2 hover:bg-gray-100 font-poppins">
          Download as Excel
        </button>
      </div>
    </div>
  </div>

  <div id="wishlistContent">
    <div *ngFor="let course of wishlist" class="course-card">
      <div class="course-info rounded-[12px] ">
        <h3 class="university-name font-poppins text-[18px] font-[500px]">{{ course.universityName | removeAffiliations  }}</h3>
        <p class="course-name font-poppins text-[#00558C]">{{ course.courseName }}</p>
        <div class="course-details">
          <div class="details-row font-poppins ">
            <div class="detail-item font-poppins">
              <img src="assets/icons/map.svg" alt="Country Icon" class="detail-icon" />
              <span class="text-[14px] font-[500px]">Country:</span>
              <div class="text-[#008DEA] text-[14px] font-[500px]">
              {{ course.country }}
              </div>
            </div>
        
            <div class="detail-item">
              <img src="assets/icons/money.svg" alt="Tuition Icon" class="detail-icon" />
              <span class="text-[14px] font-[500px]">Tuition Fees:</span>
              <div class="text-[#008DEA] text-[14px] font-[500px]">
                {{
                  course.country === 'US' ? '$' :
                  course.country === 'Germany' ? '€' :
                  course.country === 'UK' ? '£' : ''
                }} {{ course.tuitionFees }}/year
                </div>
            </div>
            
        
            <div class="detail-item">
              <img src="assets/icons/calendar-icon.svg" alt="Duration Icon" class="detail-icon" />
              <span class="text-[14px] font-[500px]">Duration:</span>
              <div class="text-[#008DEA] text-[14px] font-[500px]">
              {{ course.courseDuration }}
              </div>
            </div>
        
            <div class="detail-item">
              <img src="assets/icons/receipt-text.svg" alt="Application Fees Icon" class="detail-icon" />
              <span class="text-[14px] font-[500px]">Application Fees:</span>
              <div class="text-[#008DEA] text-[14px] font-[500px]">
                {{ formatApplicationFee(course) }}
                </div>
            </div>
            
          </div>
        </div>
        
        
      </div>
      <div class="actions ">
        <button 
        class="remove-button font-poppins text-[12px]" (click)="removeFromWishlist(course)"
        >
          <img src="assets/icons/trash.svg" alt="Remove Icon" class="icon" />
          Remove
        </button>
        
        <!-- <button class="apply-button">Apply Now</button> -->
      </div>
    </div>
  </div>
</div>