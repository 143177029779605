<div class="flex min-h-screen bg-gray-100">
  <!-- Sidebar -->
  <div class="w-1/2 bg-gray-200 p-6 flex flex-col items-center left-half">
    <img *ngIf="partnerLogo" [src]="partnerLogo" alt="Partner Logo" class="w-[176px] h-[34px]" /> 

    <div class="sidebar flex flex-col items-center justify-center mt-40 w-11/12 h-1/2">
      <img src="https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1729246686447Illustrations%201.png" alt="Education Icon" class="mb-6 w-[240px] h-[240px]"> <!-- Placeholder image -->
      <h1 class="text-[#00558C] font-semibold text-3xl mb-4 font-poppins">Start Your Next Learning</h1>
      <h1 class="text-[#00558C] font-semibold text-3xl mb-4 font-poppins">Adventure!</h1>
    </div>
  </div>
  
  <div class="login-container">

      <div class="sidebar">
        <h2 class="login-heading">Login</h2>
      </div>

      <form [formGroup]="phoneForm" (ngSubmit)="login()" class="space-y-4 w-full">
         
        <div *ngIf="!isOtpMode" class="mobile-container">
          <label for="mobile" class="block text-sm font-medium text-gray-700 ml-10 mb-6 font-poppins mobile-number">Mobile Number</label>
          <!-- ngx-intl-tel-input Component for International Phone Input -->
          <ngx-intl-tel-input
            [cssClass]="'custom'"
            [preferredCountries]="[CountryISO.India]"
            [selectFirstCountry]="true"
            [enableAutoCountrySelect]="true"
            [enablePlaceholder]="false"
            [searchCountryFlag]="true"
            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
            [phoneValidation]="true"
            formControlName="mobile"
            class="number-container"
            name="mobile">
          </ngx-intl-tel-input>

          <div class="mt-6 font-poppins ml-[40px] text-gray-400 text-[14px] text-below-mobile">
            <p>{{partnerConsent}}</p>
            <p>By continuing you agree to our <span class="text-blue-500">Terms Conditions & Privacy Policy</span></p>
          </div>
        </div>
        

        <div *ngIf="isOtpMode" class="otp-input-wrapper">
          <p class="font-poppins">Enter the 6-digit OTP sent to your phone ****{{ phoneForm.get('mobile')?.value?.nationalNumber | slice:-4 }}.</p>
          <a class="text-[#008DEA] underline font-poppins" (click)="editMobileNumber()">Edit Mobile Number</a>
          
          <div formArrayName="otpDigits" class="custom-input-otp">
            <input
              *ngFor="let control of otpDigits.controls; let i = index"
              type="text"
              maxlength="1"
              [formControlName]="i"
              required
              class="otp-box"
              (keyup)="moveFocus(i)"
            />
          </div>
        

          <p class="text-gray-500 text-sm mt-4 font-poppins">
            Request new code in 
            <span *ngIf="countdown > 0" class="text-[#008DEA]">00:{{ countdown }}</span>
            <button *ngIf="countdown === 0" class="text-[#008DEA] underline" (click)="resendOtp()">Resend OTP</button>
          </p>
          
        </div>

        <div class="fixed-bottom-btn">
          <button type="submit"
                  class="w-full text-white justify-center align-middle font-semibold">
            {{ isOtpMode ? 'Verify OTP' : 'Send OTP' }}
          </button>
        </div>
      </form>
    </div>
  </div>
