<div class="min-h-screen bg-white flex flex-col relative overflow-hidden">
  <!-- Header Section -->
  <header class="flex justify-between items-center py-6 px-8 bg-white">
    <div class="flex items-center">
      <img *ngIf="partnerLogo" [src]="partnerLogo" alt="Partner Logo" class="w-[176px] h-[34px]" />
    </div>
    <div class="flex space-x-4">
      <button (click)="navigateToLogin()" class="text-[#008DEA] border border-blue-600  mt-2 py-3 px-8 rounded-[8px] font-poppins hover:bg-blue-50 transition">
        Login
      </button>
      <button (click)="navigateToRegister()" class="bg-[#008DEA] text-white font-[600px] mt-2 py-3 px-8 rounded-[8px] shadow-lg font-poppins hover:bg-blue-700 transition">
        Find Your College!
      </button>
    </div>
  </header>

  <!-- Main Section -->
  <div class="flex-grow flex flex-col items-center justify-center text-center">
    <div class="flex">
      <!-- University Image Placeholder -->
      <img src="https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1729342617936uni.png" alt="University Image" class="mb-2 w-[104px] h-[80px] object-cover mr-4" />
      <h2 class="text-7xl font-bold text-[#008DEA] font-poppins">Find Your Next</h2>
      <img src="https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1729342666288student.png" alt="Person Image" class="mb-2 w-[104px] h-[80px] object-cover ml-4" /> 
    </div>
    <div>
      <h2 class="text-7xl font-bold text-[#008DEA] font-poppins">Academic Adventure!</h2>
    </div>
    <div class="mb-2 mt-4">
      <p class="text-gray-600 text-lg font-poppins">Explore and compare top universities abroad to find</p>
      <p class="text-gray-600 text-lg font-poppins">the perfect fit for your academic goals.</p>
    </div>
    <!-- Call to Action Button -->
    <button (click)="navigateToRegister()" class="bg-[#008DEA] text-white mt-12 py-3 px-8 rounded-[8px] shadow-lg hover:bg-blue-700 transition font-poppins">
      Find Your College!
    </button>
  </div>
  
  <!-- Decorative Boxes (Background Shapes) -->
  <div class="fixed left-4 bottom-4 space-y-4 z-10">
    <div>
    <div class="bg-[#CCE8FB] w-28 h-28 rounded-lg"></div>
    </div>
    <div class="flex gap-4">
      <div class="bg-[#FFD7A94D] w-28 h-28 rounded-lg"></div>
      <div class="bg-[#FFE1594D] w-28 h-28 rounded-lg"></div>
    </div>
    <div class="flex gap-4">
      <div class="bg-[#59DAFF4D] w-28 h-28 rounded-lg"></div>
      <div class="bg-[#40BEEC4D] w-28 h-28 rounded-lg"></div>
      <div class="bg-[#9993A14D] w-28 h-28 rounded-lg"></div>
    </div>
  </div>

  <div class="fixed right-0 bottom-4 space-y-4 z-10">
    <div class="flex justify-end">
      <div class="bg-[#CCE8FB] w-28 h-28 rounded-lg"></div>
    </div>
    <div class="flex justify-end gap-4">
      <div class="bg-[#FFD7A94D] w-28 h-28 rounded-lg"></div>
      <div class="bg-[#FFE1594D] w-28 h-28 rounded-lg"></div>
    </div>
    <div class="flex justify-end gap-4">
      <div class="bg-[#59DAFF4D] w-28 h-28 rounded-lg"></div>
      <div class="bg-[#40BEEC4D] w-28 h-28 rounded-lg"></div>
      <div class="bg-[#9993A14D] w-28 h-28 rounded-lg"></div>
    </div>
  </div>

</div>
