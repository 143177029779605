<div class="container mx-auto">
    <div *ngIf="studentDetails">
    <h1 class="text-4xl font-bold mt-1">Student Details</h1>
      <div class="bg-white shadow-md rounded px-8 pt-1 pb-8 mb-4">
          <h3>Selected Options</h3>
          <p>Target Country: {{ studentDetails.country || '-' }}</p>
          <p>Target Course: {{ studentDetails.course || '-' }}</p>
          <p>Degree Level: {{ studentDetails.degree || '-' }}</p>
          <p>English Test: {{ studentDetails.english_test || '-' }}</p>
          <p>Standardized Test: {{ studentDetails.standardized_test || '-' }}</p>
          <p>Years of Experience: {{ studentDetails.years_of_experience || '-' }}</p>
      </div>
    </div>
  
    <h2 class="text-2xl font-semibold mb-4">Shortlisted Courses</h2>
    <div *ngIf="wishlistItems.length === 0" class="text-gray-600">No courses shortlisted yet.</div>
  
    <div *ngFor="let course of wishlistItems" class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <h3 class="text-xl font-bold">{{ course.university }}</h3>
      <p>{{ course.course }}</p>
    </div>
  </div>
  