<div class="header2">
  <div>
    <img *ngIf="partnerLogo" 
    [src]="partnerLogo" 
    alt="Partner Logo" 
    class="max-h-[34px] md:max-h-[40px] lg:max-h-[50px] 
           min-h-[20px] w-auto 
           max-w-[176px] md:max-w-[200px] lg:max-w-[250px]
           object-contain" />
  </div>
  <div class="flex px-2 py-2">
      <div class="mr-8 flex btn btn-primary">
        <div class="mr-1">
          <img 
          src="assets/icons/phone-white.svg" 
          alt="Phone Icon" 
        />
        </div>
        <button (click)="openConnectModal()">
          Connect to Counsellor
        </button>
      </div>
      
      <!-- Connect Counsellor Component -->
      <app-connect-counsellor
       #connectCounsellor 
       [puid]="puid" 
       (requestSubmitted)="onRequestSubmitted()"
       ></app-connect-counsellor>

      <button class="relative mr-8 wishlist-heart flex border border-[#212529] rounded-md px-1" (click)="viewWishlist()">
        <div>
        <img 
          src="assets/icons/red-heart.svg" 
          alt="Wishlist Heart Icon" 
          class="cursor-pointer w-5 h-5 hover:opacity-75 mt-[3px]"
        />
        </div>
        <div class="font-poppins ml-2 shortlist-button">My Shortlist </div>
        <div class="text-[#008DEA] ml-1 font-semibold wishlist-length">({{ wishlistLength }})</div>
        <!-- <div class="absolute top-0 right-0 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs font-bold">

          {{ wishlistLength }}
        </div> -->
      </button>

      <div class="heart-button-mobile relative inline-flex mr-3 md:hidden">
        <img 
          src="assets/icons/heart.svg" 
          alt="Wishlist Heart Icon" 
          class="cursor-pointer w-8 h-8 hover:opacity-75"
          (click)="viewWishlist()"
        />
        <div class="absolute -top-1 -right-2 bg-red-500 text-white rounded-full w-4 h-4 flex items-center justify-center text-xs font-bold">
          {{ wishlistLength }}
        </div>
      </div>
      

      
    <div class="flex">
      <img
        src="assets/icons/profile.svg" 
        alt="Profile Photo"
        (click)="viewProfile()"
        class="cursor-pointer w-8 h-8 hover:opacity-75"
      >
      <p class="ml-2 font-poppins font-[400px] text-black text-[12px] hidden md:block">{{this.initialFilter.firstName}}</p>
    </div>
  </div>
</div>

<div class="header hide-on-mobile" >
  <div>
    <div class="flex">
    <img src="assets/icons/home.svg" alt="Uni Icon" class="w-[29px] h-[35px] mr-2" />
    <h2 class="font-[600px] font-poppins text-[#212529]">
      {{ filteredConfig.length }} Courses in {{ uniqueUniversitiesCount }} Universities
    </h2>
    </div>
    <p class="text-[#959595] font-poppins">Recommendation for you</p>
  </div>
  <div class="target-container">
    <div class="target-item">
      <span class="target-label ">Target Destination</span>
      <span class="target-value">
        <img *ngIf="getCountryFlag(initialFilter.country)" 
             [src]="getCountryFlag(initialFilter.country)" 
             alt="{{ initialFilter.country }} Flag" 
             class="flag-icon mr-2 w-[20.63px] h-[14px]">
        {{ initialFilter.country }}
      </span>
    </div>
    <div class="target-item">
      <span class="target-label">Target Degree</span>
      <span class="target-value">
        {{ initialFilter.studyLevel === 'Postgraduate' ? "Master's" : initialFilter.studyLevel === 'Undergraduate' ? "Bachelor's" : initialFilter.studyLevel }}
      </span>
    </div>
    
    <div class="target-item">
      <span class="target-label">Course Specialization</span>
      <span class="target-value">{{ initialFilter.course }}</span>
    </div>
  </div>  
</div>

<div class="flex justify-between filter-wrapper font-poppins">
  <div class="flex justify-between font-poppins">
    <div class="flex">
    <div class="flex items-center h-10 w-32 bg-white filter-button-container">
      <button
        class="filter-button flex items-center font-poppins text-sm bg-[#00558C] filter-button-blue"
        (click)="toggleFilterDrawer()"
      >
        <img
          src="assets/icons/filter-white.svg"
          alt="Filter Icon"
          class="filter-icon w-[19px] h-[19px] mr-2"
        />
        <span class="text-white">Filter</span>
      </button>
    </div>
    
    <div class="frontend-filters">
    <label class="flex items-center space-x-2 ml-12 mb-1">
      <input
        type="checkbox"
        [(ngModel)]="isScholarshipFilterEnabled"
        (change)="applyLocalFilters()"
        class="form-checkbox text-blue-600"
      />
      <span class="text-sm font-medium text-gray-700">Is Scholarship Available?</span>
    </label>

    <!-- Application Fee Waiver Checkbox -->
    <label class="flex items-center space-x-2 ml-12 mb-1">
      <input
        type="checkbox"
        [(ngModel)]="isApplicationFeeWaiverEnabled"
        (change)="applyLocalFilters()"
        class="form-checkbox text-blue-600"
      />
      <span class="text-sm font-medium text-gray-700">Application Fee Waiver?</span>
    </label>


    <label class="flex items-center space-x-2 ml-12 mb-1">
      <input
        type="checkbox"
        [(ngModel)]="isLoanOfferEnabled"
        (change)="applyLocalFilters()"
        class="form-checkbox text-blue-600"
      />
      <span class="text-sm font-medium text-gray-700">Faster Loan Sanctions</span>
    </label>
    </div>
    <!-- <label class="flex items-center space-x-2 ml-12">
      <input
        type="checkbox"
        [(ngModel)]="isEnglishTestWaiverEnabled"
        (change)="applyLocalFilters()"
        class="form-checkbox text-blue-600"
      />
      <span class="text-sm font-medium text-gray-700">Any English Test Waiver?</span>
    </label> -->
    </div>
  </div>

  <div class="sort-by-container" clickOutside (clickOutside)="isSortDropdownOpen = false">
    <button class="sort-button flex items-center text-[#00000099] font-[500px] font-poppins text-[16px]" (click)="toggleSortDropdown()">
      <img src="assets/icons/sort-by.svg" alt="Sort Icon" class="mr-2 w-4 h-4" />
      Sort by: {{ sortOptionLabel }}
    </button>
    <div *ngIf="isSortDropdownOpen" class="absolute bg-white border mt-1 rounded shadow-lg z-10 w-[250px]">
      <button class="sort-option p-2 w-full text-left" (click)="sortCourses('high-to-low')">Tuition Fees: High to Low</button>
      <button class="sort-option p-2 w-full text-left" (click)="sortCourses('low-to-high')">Tuition Fees: Low to High</button>
      <button class="sort-option p-2 w-full text-left" (click)="sortCourses('duration-low-to-high')">Duration: Low to High</button>
      <button class="sort-option p-2 w-full text-left" (click)="sortCourses('duration-high-to-low')">Duration: High to Low</button>
    </div>
  </div>
</div>

<div class="visible-only-mobile-uni">
  <div class="flex">
    <img src="assets/icons/home.svg" alt="Uni Icon" class="w-[29px] h-[35px] mr-2 ml-4" />
    <h2 class="font-[500px] font-poppins text-[#212529] text-xl"><b>{{ filteredConfig.length }} Universities</b> Recommendation for you</h2>
    </div>
</div>


<div *ngIf="isLoading" class="flex flex-col justify-center items-center">
  <dotlottie-player
    src="https://lottie.host/b909175e-70e7-44a3-90d3-3e653d302fdc/TQ1LaZcdbq.json"
    background="transparent"
    speed="1"
    style="width: 300px; height: 300px;"
    loop
    autoplay>
  </dotlottie-player>
  <h1 class="font-poppins font-semibold text-[26px] ml-8">Finding you the best universities...</h1>
</div>

<div class="overlay" [ngClass]="{ 'active': isFilterDrawerOpen }"></div>

<div class="filter-drawer" [ngClass]="{ 'open': isFilterDrawerOpen }">
  <div class="drawer-header">
    <div class="text-[26px] font-[500px] font-poppins">
      <button class="filter-button flex items-center font-[500px] font-poppins text-[17px]" (click)="toggleFilterDrawer()">
        <img src="assets/icons/filter.svg" alt="Filter Icon" class="filter-icon w-[19px] h-[19px] mr-2" />
        Filter
      </button>
    </div>
    <button class="close-button" (click)="toggleFilterDrawer()">
      <img src="assets/icons/cross.svg" alt="Close Icon" class="icon" />
    </button>
  </div>

  <!-- Scrollable Content Wrapper -->
  <div class="filter-content-wrapper">
    <div class="filter-content">
      <div>
        <label class="font-poppins text-[12px] font-[400px] text-[#00000080]">Country</label>
        <select [(ngModel)]="currentFilter.country" class="font-poppins text-[14px] font-[500px] text-[#212529]">
          <option value="">Select a Country</option>
          <option value="US">US</option>
          <option value="UK">UK</option>
          <option value="Australia">Australia</option>
          <option value="Germany">Germany</option>
        </select>
      </div>

      <div>
        <label class="font-poppins text-[12px] font-[400px] text-[#00000080]">Degree</label>
        <select [(ngModel)]="currentFilter.studyLevel" class="font-poppins text-[14px] font-[500px] text-[#212529]">
          <option value="">Select a Degree</option>
          <option value="Bachelors">Bachelors</option>
          <option value="Masters">Masters</option>
        </select>
      </div>

      <div>
        <label class="font-poppins text-[12px] font-[400px] text-[#00000080] ">Specialization</label>
        <div class="relative" clickOutside (clickOutside)="isSpecializationDropdownOpen = false">
          <!-- Dropdown Toggle (Displays Selected Specialization) -->
          <div
            (click)="toggleSpecializationDropdown()"
            class="specialization-dropdown"
          >
            {{ selectedSpecialization || 'Select a Specialization' }}
          </div>
      
          <!-- Dropdown Menu with Search (only visible when isSpecializationDropdownOpen is true) -->
          <div *ngIf="isSpecializationDropdownOpen" class="absolute  bg-white border border-gray-300 mt-2 w-full max-h-60 overflow-y-auto z-10">
            <!-- Search Input -->
            <input
              type="text"
              [(ngModel)]="specializationSearchTerm"
              placeholder="Search Specialization..."
              class="w-full p-2 border-b border-gray-300 outline-none text-[14px] font-poppins"
            />
      
            <!-- Filtered Options -->
            <div *ngFor="let specialization of filteredSpecializations()">
              <div
                (click)="selectSpecialization(specialization)"
                class="p-2 cursor-pointer hover:bg-gray-100"
              >
                {{ specialization }}
              </div>
            </div>
          </div>
        </div>
      </div>
      
      

      <div>
        <label class="font-poppins text-[12px] font-[400px] text-[#00000080]">Course Duration</label>
        <select [(ngModel)]="currentFilter.courseDuration" class="font-poppins text-[14px] font-[500px] text-[#212529]" style="max-height: 40px; overflow-y: auto;">
          <option value="">Select Duration</option>
          <option *ngFor="let duration of courseDuration" [value]="duration">{{ duration }}</option>
        </select>
      </div>
      

      <div>
        <label class="font-poppins text-[12px] font-[400px] text-[#00000080]">Tuition Fees</label>
        
        <div class="slider-container mt-4">
          
          <div class="relative">
            <input 
              [(ngModel)]="currentFilter.tuitionFees" 
              type="range" 
              min="0" 
              max="100000" 
              step="10000"
              class="custom-range"
              (input)="updateSliderBackground($event)"
            >
            
            <div class="step-markers">
              <div class="step-mark" *ngFor="let val of marks">
                <div class="mark"></div>
                <span class="mark-value">{{val}}K</span>
              </div>
            </div>
          </div>
      
          <div class="mt-6">
            <span class="text-[#212529] font-medium">
              {{
                currentFilter.country === 'US' ? '$' :
                currentFilter.country === 'Germany' ? '€' :
                currentFilter.country === 'Australia' ? 'AUD' :
                currentFilter.country === 'UK' ? '£' : '$'
              }}0 - 
              {{
                currentFilter.country === 'US' ? '$' :
                currentFilter.country === 'Germany' ? '€' :
                currentFilter.country === 'Australia' ? 'AUD' :
                currentFilter.country === 'UK' ? '£' : '$'
              }}{{ currentFilter.tuitionFees | number:'1.0-0' }}
            </span>
          </div>
        </div>
      </div>
      

      <div>
        <label class="font-poppins text-[12px] font-[400px] text-[#00000080]">Backlogs</label>
        <input [(ngModel)]="currentFilter.backlogs" type="number" placeholder="e.g., 0" class="font-poppins text-[14px] font-[500px] text-[#212529]">
      </div>

      <!-- <div class="flex items-center justify-between checkbox-row">
        <label for="scholarship" class="font-poppins text-[12px] font-[400px] text-[#00000080] whitespace-nowrap">
        Is Scholarship Available?
        </label>
        <input id="scholarship" type="checkbox" [(ngModel)]="currentFilter.scholarshipAvailable" class="custom-checkbox font-poppins text-[14px] font-[500px] text-[#212529]">
      </div>
      
      <div class="flex items-center justify-between checkbox-row">
        <label for="englishTest" class="font-poppins text-[12px] font-[400px] text-[#00000080] whitespace-nowrap">
        Any English Test Waiver?
        </label>
        <input id="englishTest" type="checkbox" [(ngModel)]="currentFilter.englishTestWaiver" class="custom-checkbox font-poppins text-[14px] font-[500px] text-[#212529]">
      </div>
      
      <div class="flex items-center justify-between checkbox-row">
        <label for="applicationFee" class="font-poppins text-[12px] font-[400px] text-[#00000080] whitespace-nowrap">
          Application Fee Waiver?
        </label>
        <input id="applicationFee" type="checkbox" [(ngModel)]="currentFilter.applicationFeeWaiver" class="custom-checkbox font-poppins text-[14px] font-[500px] text-[#212529]">
      </div> -->
      
      <button class="apply-button font-poppins mt-8 bg-[#008DEA]" (click)="applyFilters()">Apply Filters</button>
      
    </div>
  </div>
</div>


<div *ngIf="filteredConfig.length === 0" class="items-center ">
<div class="no-match mt-8 ml-5 flex flex-col justify-center align-middle items-center h-64 w-[1500px] bg-gray-100 rounded-lg shadow-lg p-6">
  <a>
    <img src="https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1729754303996smiley.png" alt="Sad Face Emoji" class="w-28 h-28 mb-4" />
  </a>
  <p class="text-xl font-poppins font-semibold text-[#FF6B00]">No University Matched</p>
  <div class="justify-center align-center">
    <p class="text-sm font-poppins text-gray-500 mt-2">We couldn't find a perfect university</p>
    <p class="text-sm font-poppins text-gray-500 mt-2 ml-10">match for your profile.</p>
  </div>
</div>
</div>


<div *ngIf="!isLoading">
<div *ngIf="filteredConfig.length > 0" class="card-grid">
  <div *ngFor="let course of filteredConfig" class="card" (click)="viewDetails(course)">

    <!-- <div class="scholarship-container">
      <img
        *ngIf="course.scholarship_available === 'Yes'"
        class="scholarship-icon"
        src="assets/icons/scholarship.svg"
        alt="Scholarship Icon"
      />
      <span class="tooltip-text">Scholarship Available</span>
    </div> -->

    <div>
      <h3 class="font-[600px] font-poppins">{{ course.university | removeAffiliations }}</h3>
      <p class="font-[400px] font-poppins text-xs">{{ course.country === 'US' ? 'USA' : course.country }}</p>
      <div class="flex">
        <div class="w-10/12">
          <p class="font-[400px] font-poppins text-xs text-[#00558C] course-name">
            {{ course.course_name }}
          </p>
        </div>
        
        <div class="relative w-1/12 ml-8" *ngIf="course.scholarship_available === 'Yes'">
          <img
            class="scholarship-icon"
            src="assets/icons/scholarship.svg"
            alt="Scholarship Icon"
          />
          <span class="tooltip-text absolute left-0 mt-1 text-xs bg-gray-200 rounded p-1 shadow-lg">
            Scholarship Available
          </span>
        </div>
      </div>
    </div>
  
    <div class="card-info mb-2">
      <div class="tution-fee font-[400px] font-poppins text-xs">
        <img src="assets/icons/money.svg"
             alt="Receipt Icon"
             [customTooltip]="'Yearly Tuition Fee'" />
        <span>
        {{
          course.country === 'US' ? '$' :
          course.country === 'Germany' ? '€' :
          course.country === 'Australia' ? 'AUD' :
          course.country === 'UK' ? '£' : ''
        }} {{ course.yearly_tuition_fees }}/year
        </span>
      </div>

      <div class="info-item font-[400px] font-poppins text-xs">
        <img src="assets/icons/calendar-icon.svg"
             alt="Duration Icon"
             [customTooltip]="'Course Duration'" />  
        <span>{{ course.duration }}</span>
      </div>
      
      <div class="application-fee font-[400px] font-poppins text-xs" *ngIf="course.application_fee">
        <img src="assets/icons/receipt-text.svg"
             alt="Receipt Icon"
             [customTooltip]="'Application Fee'" />
        <span>
          {{
            (course.application_fee === 'Waiver'
              ? 'Waiver'
              : (course.country === 'US' ? '$' :
                 course.country === 'Germany' ? '€' :
                 course.country === 'Australia' ? 'AUD' :
                 course.country === 'UK' ? '£' : '') + ' ' + course.application_fee
            ).length <= 7
              ? (course.application_fee === 'Waiver'
                  ? 'Waiver'
                  : (course.country === 'US' ? '$' :
                     course.country === 'Germany' ? '€' :
                     course.country === 'Australia' ? 'AUD' :
                     course.country === 'UK' ? '£' : '') + ' ' + course.application_fee)
              : ''
          }}
        </span>
      </div>
    </div>

    <div class="flex ">
      <!-- Faster Offer Letter Icon -->
      <div 
        *ngIf="course.university.includes('(Shorelight)') ||  
                course.university.includes('(Study Group)') "
        class="offer-tag"
      >
        <img src="assets/icons/faster-offer-letter-tag.svg" alt="Faster Offer Letter Icon" class="offer-icon" />
      </div>
    
      <!-- Loan Offer Icon -->
      <div *ngIf="course.loan_offers === 'Yes'" class="offer-tag">
        <img src="assets/icons/faster-loan-tag.svg" alt="Loan Offer Icon" class="button-icon" />
      </div>
    </div>
    
  
    <div class="action-buttons font-[400px] font-poppins text-xs">
      <a 
        href="{{ course.website }}" 
        target="_blank" 
        class="website-button" 
        (click)="$event.stopPropagation()">
        <img src="assets/icons/global.svg" alt="Earth Icon" class="button-icon" />
        Visit Website
      </a>

      <button 
        class="wishlist-button font-[400px] font-poppins text-xs" 
        [ngClass]="{ 'active': course.isInWishlist }" 
        (click)="addToWishlist(course); $event.stopPropagation()">
        <img [src]="course.isInWishlist ? 'assets/icons/active-heart.svg' : 'assets/icons/heart.svg'" alt="Heart Icon" class="button-icon" />
        {{ course.isInWishlist !== undefined ? (course.isInWishlist ? 'Shortlisted' : 'Shortlist') : 'Shortlist' }}
      </button>
    </div>
  </div>
  </div> 
</div>

