<div class="header2">
  <div>
    <img *ngIf="partnerLogo" 
    [src]="partnerLogo" 
    alt="Partner Logo" 
    class="max-h-[34px] md:max-h-[40px] lg:max-h-[50px] 
           min-h-[20px] w-auto 
           max-w-[176px] md:max-w-[200px] lg:max-w-[250px]
           object-contain" />
  </div>
  <div class="flex">

      <!-- <div class="relative inline-block mr-10">
        <img 
          src="assets/icons/heart.svg" 
          alt="Wishlist Heart Icon" 
          (click)="viewWishlist()"
          class="cursor-pointer w-8 h-8 hover:opacity-75"
        />
        <div class="absolute top-0 right-0 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs font-bold">

          {{ wishlistLength }}
        </div>
      </div>
       -->
    <!-- <div class="flex">
      <img
        src="assets/icons/profile.svg" 
        alt="Profile Photo"
        (click)="viewProfile()"
        class="cursor-pointer w-8 h-8 hover:opacity-75"
      >
      <p class="ml-2 font-poppins font-[400px] text-black text-[12px]">{{this.initialFilter.firstName}}</p>
    </div> -->
  </div>
</div>

<div *ngIf="course" class="p-4">
  <!-- Header Section -->
  <div class="flex items-center mb-4">
    <button (click)="prevStep()" class="text-gray-600 mb-4 mr-4 mt-2.5">
      <img src="assets/icons/arrow-left.svg" alt="Back" class="w-6 h-6" />
    </button>
    <h4 class="mb-2 font-poppins font-[500px] text-[16px]">Universities > {{ course.university | removeAffiliations  }}</h4>
  </div>

  <!-- Main Content Grid -->
  <div class="grid grid-cols-1 md:grid-cols-3 main-box">
    
    <!-- Left Column: University Information -->
    <div>
      <div class="left-uni-detail-container">
        <p class="text-xl font-bold mb-2 font-poppins">{{ course.university | removeAffiliations }}</p>
        <p class="text-sm mb-1 font-poppins text-[#00558C]">{{ course.course_name }}</p>

        <hr class="border-t border-black-600">

        <div class="space-y-2 font-poppins">

          <div class="flex items-center space-x-2 justify-between font-poppins">
            <div class="flex">
              <img src="assets/icons/map.svg" alt="Country Icon" class="w-5 h-5">
              <p class="text-gray-500 ml-1">Country</p>
            </div>
            <div>
              <p class="ml-auto text-[#0F4B87] font-[500px]">{{ course.country === 'US' ? 'USA' : course.country }}</p>
            </div>
          </div>

          <div class="flex items-center space-x-2 justify-between">
            <div class="flex">
              <img src="assets/icons/money.svg" alt="Country Icon" class="w-5 h-5">
              <p class="text-gray-500 ml-1">Duration:</p>
            </div>
            <div>
              <p class="ml-auto text-[#0F4B87]">{{ course.duration }}</p>
            </div>
          </div>

          <div class="flex items-center space-x-2 justify-between">
            <div class="flex">
              <img src="assets/icons/calendar-icon.svg" alt="Country Icon" class="w-5 h-5">
              <p class="text-gray-500 ml-1">Tuition Fees</p>
            </div>
            <div>
              <p class="ml-auto text-[#0F4B87]"> {{ course.country === 'US' ? '$' : 
                (course.country === 'Germany' ? '€' : 
                (course.country === 'UK' ? '£' : 
                (course.country === 'Australia' ? 'AUD ' : '')))}}{{ course.yearly_tuition_fees }} / year</p>
            </div>
          </div>

          <div class="flex items-center space-x-2 justify-between">
            <div class="flex">
              <img src="assets/icons/teacher.svg" alt="Country Icon" class="w-5 h-5">
              <p class="text-gray-500 ml-1">Degree</p>
            </div>
            <div>
              <p class="ml-auto text-[#0F4B87]"> {{ course.study_level }}</p>
            </div>
          </div>


          <div class="flex items-center space-x-2 justify-between">
            <div class="flex">
              <img src="assets/icons/receipt-text.svg" alt="Country Icon" class="w-5 h-5">
              <p class="text-gray-500 ml-1">Application Fees</p>
            </div>
            <div>
              <p class="ml-auto text-[#0F4B87]">
                <ng-container *ngIf="course.application_fee !== 'Waiver' && course.application_fee !== null">
                  {{
                    course.country === 'US' ? '$' :
                    course.country === 'Germany' ? '€' :
                    course.country === 'Australia' ? 'AUD' :
                    course.country === 'UK' ? '£' : ''
                  }}
                </ng-container>
                {{ course.application_fee !== null ? course.application_fee : 'N/A' }}
              </p>
            </div>
          </div>
          

          <div class="flex items-center space-x-2 justify-between">
            <div class="flex">
              <img src="assets/icons/map.svg" alt="Country Icon" class="w-5 h-5">
              <p class="text-gray-500 ml-1 ">Location</p>
            </div>
            <div>
              <p class="ml-auto text-[#0F4B87]"> {{ course.campus }}, {{ course.country }}</p>
            </div>
          </div>
          
        </div>

        <div class="action-buttons font-[400px] font-poppins text-xs">
          <a 
            href="{{ course.website }}" 
            target="_blank" 
            class="website-button flex items-center space-x-2 px-4 py-2 rounded" 
            (click)="$event.stopPropagation()">
            <img src="assets/icons/global.svg" alt="Earth Icon" class="button-icon w-4 h-4 mr-1" />
            Visit Website
          </a>
        
          <button 
            class="wishlist-button font-[400px] font-poppins text-xs" 
            [ngClass]="{ 'active': course.isInWishlist }"
            (click)="addToWishlist(course, $event); $event.stopPropagation()">
            <img 
              [src]="course.isInWishlist ? 'assets/icons/active-heart.svg' : 'assets/icons/heart.svg'" 
              alt="Heart Icon" 
              class="button-icon" 
            />
            {{ course.isInWishlist ? 'Shortlisted' : 'Shortlist' }}
          </button>

        </div>
        
      </div>

      
    </div>



    <!-- Right Column: Entry Requirements -->
    <div class="bg-white p-4 col-span-2 border rounded-[16px] right-half">

      <!-- Buttons -->
      <div class="flex space-x-4 sticky top-0 bg-white p-4 shadow-sm z-10 justify-between mb-4 scroll-buttons-desktop">
        <button (click)="scrollToSection('entry-requirements')" 
                [ngClass]="{'text-[#008DEA] border-b-2 border-[#008DEA] font-semibold': activeSection === 'entry-requirements'}"
                class="font-poppins hover:text-blue-500">
          Entry Requirements
        </button>
        <button (click)="scrollToSection('fees')"
                [ngClass]="{'text-[#008DEA] border-b-2 border-[#008DEA] font-semibold': activeSection === 'fees'}"
                class="font-poppins hover:text-blue-500">
          Tuition Fees
        </button>

        <button (click)="scrollToSection('scholarships')"
                [ngClass]="{'text-[#008DEA] border-b-2 border-[#008DEA] font-semibold': activeSection === 'scholarships'}"
                class="font-poppins hover:text-blue-500">
          Scholarships
        </button>

        <!-- <button (click)="scrollToSection('rankings')"
                [class.text-blue-500]="activeSection === 'rankings'"
                class="font-poppins hover:text-blue-500">
          Rankings
        </button> -->
        <button (click)="scrollToSection('job-opportunities')"
                [ngClass]="{'text-[#008DEA] border-b-2 border-[#008DEA] font-semibold': activeSection === 'job-opportunities'}"
                class="font-poppins hover:text-blue-500">
          Job Opportunity
        </button>
        <button (click)="scrollToSection('cost-of-living')"
                [ngClass]="{'text-[#008DEA] border-b-2 border-[#008DEA] font-semibold': activeSection === 'cost-of-living'}"
                class="font-poppins hover:text-blue-500">
          Cost of Living
        </button>
      </div>

      <!-- Entry Requirements -->
      <div class="p-4 border border-gray-200 rounded-[16px] mb-4">
      <div  id="entry-requirements" class="text-lg font-semibold mb-2 font-poppins text-[#008DEA] rounded-[16px] flex">
        <div class="flex">
          <img src="assets/icons/entry-requirements-icon.svg" alt="Country Icon" class="w-5 h-5 mt-[3px]">
          <p class="ml-2">Entry Requirements</p>
        </div>
      </div>
      
        <!-- English Test Scores -->
        <div class="text-left mb-6">
          <p class="font-poppins text-[#212529] mb-4 font-medium">English Test</p>
          <div class="grid grid-cols-4 gap-4">
            <ng-container *ngFor="let test of course.test_scores">
              <div *ngIf="test.test_name === 'IELTS' || test.test_name === 'TOEFL' || test.test_name === 'PTE' || test.test_name === 'DET'" 
                   class="flex flex-col font-normal text-[14px]">
                <span class="text-gray-500 text-sm mb-1">{{test.test_name}}:</span>
                <span class="text-[#00558C] text-[24px] font-semibold">
                  {{ test.min_score || 'N/A' }}
                </span>
              </div>
            </ng-container>
          </div>
        </div>
        
        <hr class="my-4 border-t border-black-600">

        <!-- Aptitude Test Scores -->
        <div class="text-left mb-6">
          <p class="font-poppins text-[#212529] mb-4 font-medium">Aptitude Test</p>
          <div class="grid grid-cols-4 gap-4">
            <ng-container *ngFor="let test of course.test_scores">
              <div *ngIf="test.test_name === 'GRE' || test.test_name === 'GMAT'" 
                   class="flex flex-col font-normal text-[14px]">
                <span class="text-gray-500 text-sm mb-1">{{test.test_name}}:</span>
                <span class="text-[#00558C] text-[24px] font-semibold">
                  {{ test.min_score || 'N/A' }}
                </span>
              </div>
            </ng-container>
          </div>
        </div>
    
      <!-- Entry Requirements Note -->
      <div class="bg-[#e2ebf1] rounded-[6px] p-2 border border-[#B3DDF9]">
      <div class="font-poppins text-[#212529]"><p class="text-[#0F4B87] font-semibold">Note:</p><br>{{ course.entry_requirements }}</div>
      </div>
      <!-- <div class="mt-2 font-poppins text-[#212529]"><p>Upto {{ course.backlog_range }} backlogs are allowed.</p></div> -->
    
      </div>

      <!-- <hr class="my-4 border-t border-black-600"> -->

      <!-- Fees Section -->
      <div class="p-4 border border-gray-200 rounded-[16px] mb-4">
      <div id="fees" class="text-[#008DEA] font-semibold font-poppins text-lg mb-4">
        <div class="flex">
          <img src="assets/icons/money.svg" alt="Country Icon" class="w-5 h-5 mt-[3px]">
          <p class="ml-2">Tution Fees</p>
        </div>
      </div>

        <!-- Yearly Tuition Fees -->
        <div class="mb-2 block">
          <p class="text-gray-500 font-poppins">Yearly Tuition Fees: </p>
          <p class="font-poppins ml-1 text-[#00558C] font-semibold text-[24px]">
            <ng-container *ngIf="course.yearly_tuition_fees !== null">
              {{ course.country === 'US' ? '$' : 
                (course.country === 'Germany' ? '€' : 
                (course.country === 'UK' ? '£' : 
                (course.country === 'Australia' ? 'AUD' : '')))}}
            </ng-container>          
            {{ course.yearly_tuition_fees }}</p>
        </div>

        <!-- Application Deadline -->
        <!-- <div class="flex mb-2">
        <p class="text-gray-500 font-poppins">Application Deadline: </p>
        <p class="font-poppins ml-1"> {{ course.application_deadline }}
        </p>
        </div> -->
      </div>
      
      <!-- <hr class="my-4 border-t border-black-600 rounded-[16px]"> -->

      <!-- Scholarships Section -->
      <div class="p-4 border border-gray-200 rounded-[16px] mb-4">
        <div id="scholarships" class="text-[#008DEA] font-semibold font-poppins text-lg mb-4">
          <div class="flex">
            <img src="assets/icons/scolarship-blue.svg" alt="Country Icon" class="w-5 h-5 mt-[3px]">
            <p class="ml-2">Scholarships</p>
          </div>
        </div>
  
        <!-- Scholarship -->
        <div class="block mb-4">
          <p class="text-gray-500 font-poppins">Scholarship: </p>
          <p class="font-poppins ml-1"> {{ course.scholarship_available }}</p>
        </div>
  
        <!-- Scholarship Details -->
        <div class="block mb-4">
          <p class="text-gray-500 font-poppins">Scholarship Details: </p>
          <p class="font-poppins ml-1"> {{ course.scholarship_details ? course.scholarship_details : '-' }}</p>
        </div>
  
        <div class="block mb-4">
          <p class="text-gray-500 font-poppins">English Proficiency Exam Waiver : </p>
          <p class="font-poppins ml-1"> {{ course.english_proficiency_required }}</p>
        </div>
      </div>

      <!-- <hr class="my-4 border-t border-black-600 rounded-[16px]"> -->
      
      <!--Ranking-->
      <!-- <div class="p-4 border border-gray-200 rounded-lg">
        <h3 id="rankings" class="text-[#00558C] font-semibold font-poppins text-lg mb-4">Rankings</h3>
        <div class="flex mb-2">
          <p class="text-gray-500 font-poppins">Webometrics National Ranking: </p>
          <p class="font-poppins ml-2"> {{ course.webometrics_national_ranking || 'NA' }}</p>
        </div>
        <div class="flex mb-2">
          <p class="text-gray-500 font-poppins">Webometrics World Ranking: </p>
          <p class="font-poppins ml-2"> {{ course.webometrics_world_ranking ||  'N/A' }}</p>
        </div>
        <div class="flex mb-2">
          <p class="text-gray-500 font-poppins">US News Ranking: </p>
          <p class="font-poppins ml-2"> {{ course.us_news_ranking || 'N/A' }}</p>
        </div>
        
        <div class="flex mb-2">
          <p class="text-gray-500 font-poppins">QS University Ranking: </p>
          <p class="font-poppins ml-2"> {{ course.qs_ranking || 'N/A' }}</p>
        </div>
      </div> -->

      <!-- <hr class="my-4 border-t border-black-600 rounded-[16px]"> -->

      <!-- Job Opportunities Section -->
      <div class="p-4 border border-gray-200 rounded-[16px] mb-4" 
        (click)="!jobDetails && fetchJobDetails()"
        [class.cursor-pointer]="!jobDetails" 
        [class.cursor-not-allowed]="jobDetails">
  
        <div  id="job-opportunities" class="text-lg mb-2 font-poppins text-[#008DEA] font-semibold">
          <div class="flex">
            <img src="assets/icons/briefcase.svg" alt="Country Icon" class="w-5 h-5 mt-[3px]">
            <p class="ml-2">Job Opportunities</p>
          </div>
        </div>
        
          <!-- Show loader if loading -->
          <div *ngIf="isLoading" class="flex items-center">
            <div class="loader mr-2"></div>
            <p>Loading job details...</p>
          </div>

          <!-- Job details content -->
          <div *ngIf="jobDetails && jobDetails.length > 0" class="mt-4">
            <div class="job-details-box">
              <div *ngFor="let job of jobDetails" 
                   class="flex flex-col justify-between p-4 rounded-lg bg-[#F8F9FA] border border-gray-200 min-h-[100px]">
                <span class="text-gray-500 text-[14px] font-medium">{{ job.jobTitle }}</span>
                <div class="flex items-baseline">
                  <span class="text-[#00558C] text-[24px] font-semibold">
                    {{ job.salaryPerYear }}
                  </span>
                  <span class="text-gray-500 text-[14px] ml-1">per year</span>
                </div>
              </div>
            </div>

            <!-- Display the source link -->
            <p *ngIf="jobDetailsSource" class="mt-4 font-poppins">
              Source: 
              <a [href]="jobDetailsSource" 
                 class="text-blue-500 hover:underline cursor-pointer" 
                 target="_blank"
                 rel="noopener noreferrer">
                view source
              </a>
            </p>
          </div>

          <!-- If no job details are available -->
          <div *ngIf="!jobDetails && jobDetails !== null && !isLoading" class="mt-4">
            <p class="font-poppins text-red-500">No job information available at this time.</p>
          </div>
      </div>

      <!-- <hr class="my-4 border-t border-black-600 rounded-[16px]"> -->
      
      <!-- Cost of Living Section -->
      <div class="p-4 border border-gray-200 rounded-[16px] mb-4">
        <div id="cost-of-living" class="text-lg mb-6 font-poppins text-[#008DEA] font-semibold flex items-center gap-2">
          <div class="flex">
            <img src="assets/icons/cost-of-living.svg" alt="Country Icon" class="w-5 h-5 mt-[3px]">
            <p class="ml-2">Cost of Living</p>
          </div>
        </div>
      
        <!-- Loading State -->
        <div *ngIf="isCostOfLivingLoading" class="flex items-center">
          <div class="loader mr-2"></div>
          <p>Loading cost of living details...</p>
        </div>
      
        <!-- Content State -->
        <div *ngIf="!isCostOfLivingLoading && costOfLivingDetails" class="flex flex-col">
          <!-- Living Type Buttons -->
           
            <div class="flex block-for-cost-of-living">
              <div class="cost-of-living-buttons">

                <button 
                  (click)="selectedLiving = 'budgetLiving'"
                  [class.bg-[#00558C]]="selectedLiving === 'budgetLiving'"
                  [class.text-white]="selectedLiving === 'budgetLiving'"
                  class="flex items-center justify-between px-4 py-2 rounded text-left font-poppins text-[#00558C]  transition-colors">
                  Economical Living
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                    <path d="M9 18l6-6-6-6"/>
                  </svg>
                </button>

                <button 
                  (click)="selectedLiving = 'middleClassLiving'"
                  [class.bg-[#00558C]]="selectedLiving === 'middleClassLiving'"
                  [class.text-white]="selectedLiving === 'middleClassLiving'"
                  class="flex items-center justify-between px-4 py-2 rounded text-left font-poppins text-[#00558C]  transition-colors">
                  Balanced Living
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                    <path d="M9 18l6-6-6-6"/>
                  </svg>
                </button>

                <button 
                  (click)="selectedLiving = 'lavishLiving'"
                  [class.bg-[#00558C]]="selectedLiving === 'lavishLiving'"
                  [class.text-white]="selectedLiving === 'lavishLiving'"
                  class="flex items-center justify-between px-4 py-2 rounded text-left font-poppins text-[#00558C]  transition-colors button-style-col">
                  Comfortable Living
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                    <path d="M9 18l6-6-6-6"/>
                  </svg>
                </button>     
              </div>

              <!-- Charts Area -->
              <div class="bg-white rounded-lg p-6 flex justify-between items-center ml-8 graph-area">
                <div class="w-4/5">
                  <app-cost-of-living-chart
                    *ngIf="costOfLivingDetails.lavishLiving && selectedLiving === 'lavishLiving'"
                    [livingData]="costOfLivingDetails.lavishLiving"
                    title="Comfortable Living">
                  </app-cost-of-living-chart>
          
                  <app-cost-of-living-chart
                    *ngIf="costOfLivingDetails.middleClassLiving && selectedLiving === 'middleClassLiving'"
                    [livingData]="costOfLivingDetails.middleClassLiving"
                    title="Balanced Living">
                  </app-cost-of-living-chart>
          
                  <app-cost-of-living-chart
                    *ngIf="costOfLivingDetails.budgetLiving && selectedLiving === 'budgetLiving'"
                    [livingData]="costOfLivingDetails.budgetLiving"
                    title="Economical Living">
                  </app-cost-of-living-chart>
                </div>
          
                <!-- Total Cost Display -->
                <div class="w-1/3 text-center">
                  <div class="text-3xl font-bold text-[#00558C]">{{ getTotalCost() }}</div>
                  <div class="text-gray-500 text-sm">Total Cost of Living</div>
                </div>
              </div>   
          </div>     
        </div>
      
        <!-- No Data State -->
        <div *ngIf="!costOfLivingDetails && costOfLivingDetails !== null && !isCostOfLivingLoading" class="mt-4">
          <p class="font-poppins text-red-500">No cost of living information available at this time.</p>
        </div>
      </div>

    </div>
    
  </div>
</div>
