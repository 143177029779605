import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WishlistService } from '../wishlist.service';
import { OpenAIService } from '../services/openai.service';
import { HttpClient, HttpErrorResponse , HttpHeaders} from '@angular/common/http';
import { PartnerService } from '../services/partner.service';

interface JobDetail {
  jobTitle: string;
  salaryPerYear: string;
}

@Component({
  selector: 'app-university-detail',
  templateUrl: './university-detail.component.html',
  styleUrls: ['./university-detail.component.scss']
})
export class UniversityDetailComponent implements OnInit {
  course: any;
  jobDetails: JobDetail[] | null = null; 
  jobDetailsSource: string | null = null; 
  isLoading = false;
  partnerLogo: string = ''; 
  puid: string = "";
  wishlistLength: number = 0; 
  wishlist: any[] = [];
  config: any[] = [];

  constructor(private router: Router,
    private partnerService:PartnerService,
    private activatedRoute: ActivatedRoute ,
    private wishlistService: WishlistService,
    private openAIService: OpenAIService) {
    const navigation = this.router.getCurrentNavigation();
    this.course = navigation?.extras?.state?.['course'];
    console.log('Course data:', this.course);
  }

  ngOnInit(): void {
    this.updateWishlistLength(); 
    const puid = this.activatedRoute.snapshot.paramMap.get('puid');
    if (puid) {
      this.puid = puid;
      this.fetchPartnerLogo(puid);
    }
    console.log(puid,20)
    if (!this.course) {
      if(puid){
        this.router.navigate(['/results/'+puid]);
      }
      else{
        this.router.navigate(['/results']);
      }
    }
  }

  prevStep(){
    const puid = this.activatedRoute.snapshot.paramMap.get('puid');
    console.log(puid,75)

    if(puid){
      this.router.navigate(['/results/'+puid]);
    }
    else{
      this.router.navigate(['/results']);
    }
  }

  
  // Function to update wishlist length
  updateWishlistLength() {
    const token = localStorage.getItem('access_token');
    const uid = localStorage.getItem('uid');

    if (!uid) {
      console.error('No UID found in local storage');
      throw new Error('No UID found in local storage');
    }

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'uid': uid 
    });

    this.wishlistService.getWishlist(headers).subscribe(
      (response: any) => {
        if (response && response.data) {
          this.wishlistLength = response.data.length; 
          console.log('Wishlist Length:', this.wishlistLength);
          this.isLoading = false;
        } else {
          this.wishlistLength = 0; 
          this.isLoading = false;
        }
      },
      (error) => {
        console.error('Error fetching wishlist:', error);
        this.wishlistLength = 0; 
        this.isLoading = false;
      }
    );
  }

  // Function to update wishlist and wishlist length
  updateWishlist() {
    const token = localStorage.getItem('access_token');
    const uid = localStorage.getItem('uid');

    if (!uid) {
      console.error('No UID found in local storage');
      throw new Error('No UID found in local storage');
    }

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'uid': uid 
    });

    this.wishlistService.getWishlist(headers).subscribe(
      (response: any) => {
        if (response && response.data) {
          this.wishlist = response.data; 
          this.wishlistLength = this.wishlist.length; 
          this.config.forEach(course => {
            course.isInWishlist = this.wishlist.some(wish => wish.courseId === course.course_id);
          });

          console.log('Wishlist Length:', this.wishlistLength);
          this.isLoading = false;
        } else {
          this.wishlistLength = 0; 
          this.isLoading = false;
        }
      },
      (error) => {
        console.error('Error fetching wishlist:', error);
        this.wishlistLength = 0; 
        this.isLoading = false;
      }
    );
  }

  fetchPartnerLogo(puid: string): void {
    this.partnerService.getPartnerLogo(puid).subscribe(
      (response) => {
        this.partnerLogo = response.logo; // Assuming the backend returns { logo: 'URL' }
      },
      (error) => {
        console.error('Error fetching partner logo:', error);
      }
    );
  }

  addToWishlist(course: any, event: MouseEvent) {
    this.wishlistService.addToWishlist(course).subscribe(
      (response: any) => {
        console.log('Course added to wishlist:', response);
        course.isInWishlist = !course.isInWishlist; 
      },
      (error) => {
        console.error('Error adding course to wishlist:', error);
      }
    );
  }


   // Fetch wishlist and mark courses already in it
   markCoursesInWishlist() {
    const token = localStorage.getItem('access_token');
    const uid = localStorage.getItem('uid');

    if (!uid) {
      console.error('No UID found in local storage');
      throw new Error('No UID found in local storage');
    }

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'uid': uid
    });

    this.wishlistService.getWishlist(headers).subscribe(
      (response: any) => {
        const wishlist = response.data; 
        this.config.forEach(course => {
          course.isInWishlist = wishlist.some((wish: any) => wish.courseId === course.course_id);
        });
      },
      (error) => {
        console.error('Error fetching wishlist:', error);
      }
    );
  }


  viewWishlist() {

    const puid = this.activatedRoute.snapshot.paramMap.get('puid');
    console.log(puid,67)

    if(puid){
      this.router.navigate(['/wishlist/'+puid]);
    }
    else{
      this.router.navigate(['/wishlist']);
    }
  }

  fetchJobDetails() {
    this.isLoading = true;
    const prompt = `Generate a list of job opportunities for graduates of ${this.course.course_name} at ${this.course.university}. Include the following for each job: the job title and the average annual salary in the currency format
    of the country ${this.course.country} (e.g., $ for USA, £ for UK, € for Eurozone, ₹ for India). Ensure that the salary information is up-to-date and accurate, based on real data from credible sources. Use data from reliable sources
    such as government labor reports, salary comparison websites (e.g., Glassdoor, PayScale, Indeed), or industry-specific reports.
    After listing the job titles and salaries, include the source of the salary information as a link.

    "exampleOutput": [
        {
            "jobTitle": "Financial Analyst",
            "salaryPerYear": "€50,000" // Adjust currency based on country
        },
        {
            "jobTitle": "Investment Manager",
            "salaryPerYear": "€65,000" // Adjust currency based on country
        },
        {
            "jobTitle": "Risk Manager",
            "salaryPerYear": "€70,000" // Adjust currency based on country
        },
        {
            "jobTitle": "Financial Controller",
            "salaryPerYear": "€80,000" // Adjust currency based on country
        },
        {
            "Source": "SOURCE OF INFORMATION link"
        }
      ]
      I just want JSON in output, nothing else, no string, nothing. Do not include any triple quotes or characters like 'json' in the output; just JSON format, that's it, without any additional characters or strings.`;

    
    this.openAIService.getJobDetails(prompt).subscribe(
      (response: any) => {
        console.log(response, 84)
        if (response && response.choices && response.choices.length > 0 && response.choices[0].message && response.choices[0].message.content) {
          try {
            const jobData = JSON.parse(response.choices[0].message.content.trim());
            
            // Extract source from the last element of the array
            const source = jobData.pop(); // Removes and retrieves the last item (source)

            if (source && source.Source) {
              this.jobDetailsSource = source.Source; // Store the source URL
            }

            this.jobDetails = jobData; // Store the remaining job details
          } catch (e) {
            console.error('Error parsing job details:', e);
            this.jobDetails = null;
          }
        } else {
          console.error('Unexpected response structure:', response);
          this.jobDetails = null;
        }
        this.isLoading = false;
      },
      (error: any) => {
        console.error('Error fetching job details:', error);
        this.jobDetails = null;
        this.isLoading = false;
      }
    );
  }
  
}
