import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private uidSource = new BehaviorSubject<string | null>(null);
  currentUid = this.uidSource.asObservable();

  setUid(uid: string) {
    this.uidSource.next(uid);
  }

  getUid(): string | null {
    return this.uidSource.value;
  }
}
