import { Component, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-connect-counsellor',
  templateUrl: './connect-counsellor.component.html',
  styleUrls: ['./connect-counsellor.component.scss'],
})

export class ConnectCounsellorComponent {
  @Input() puid: string = '';
  isModalOpen: boolean = false;
  selectedDate: string = '';
  selectedTime: string = '';
  selectedTimeSlot: string = '';

  // Predefined time slots
  timeSlots: string[] = ['8:00 - 11:00', '11:00 - 14:00', '14:00 - 17:00', '17:00 - 20:00'];
  private baseUrl: string = environment.apiBaseUrl;
  constructor(private http: HttpClient) {}

  // Open Modal
  openModal() {
    this.isModalOpen = true;
  }


  closeModal() {
    this.isModalOpen = false;
    this.selectedDate = '';
    this.selectedTime = '';
  }


  bookCounsellorCall() {
    if (!this.selectedDate || !this.selectedTimeSlot) {
      alert('Please select both a date and time slot.');
      return;
    }
    const uid = localStorage.getItem('uid');
    if (!uid) {
      alert('User ID is missing. Please log in again.');
      return;
    }
    const requestData = {
      puid: this.puid, 
      uid: uid,
      date: this.selectedDate,
      timeSlot: this.selectedTimeSlot,
    };
    console.log(requestData, 46)
    // Send data to the backend
    this.http.post(`${this.baseUrl}/partner-details/connect-counsellor`, requestData).subscribe(
      (response) => {
        console.log('Call booked successfully:', response);
        alert('Your call has been booked!');
        this.closeModal();
      },
      (error) => {
        console.error('Error booking call:', error);
        alert('An error occurred while booking the call. Please try again.');
      }
    );
  }
}
