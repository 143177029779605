<div class="min-h-screen bg-gray-100 p-8 flex">
  <!-- Sidebar -->
  <div class="w-1/4 bg-white shadow-lg rounded-lg p-6">
    <div class="flex flex-col items-center">
      <h2 class="text-xl font-bold mt-2">{{ personalDetails.firstName }} {{ personalDetails.lastName }}</h2>
      <div class="text-gray-500 mt-1 text-center">
        <div class="text-sm"><strong>Phone:</strong> {{ personalDetails.phoneNumber }}</div>
        <div *ngIf="personalDetails?.state" class="text-sm"><strong>State:</strong> {{ personalDetails.state }}</div>
      </div>
      <div class="mt-8 space-y-4 w-full">
        <a class="block w-full py-2 text-center bg-blue-500 text-white rounded-lg font-semibold">Personal Details</a>
        <button (click)="logout()" class="block w-full py-2 text-center bg-gray-400 text-white rounded-lg font-semibold">Logout</button>
      </div>
    </div>
  </div>

  <!-- Main Content -->
  <div class="w-3/4 bg-white shadow-lg rounded-lg ml-8 p-8">
    <div class="flex justify-between items-center mb-6">
      <h1 class="text-2xl font-bold">My Account</h1>
      <button (click)="viewResults()" class="bg-blue-500 text-white py-2 px-4 rounded-lg">Go to Results</button>
    </div>

    <!-- Personal Details Form -->
    <form class="space-y-8">
      <h2 class="text-xl font-semibold text-blue-500">Personal Details</h2>
      <div class="grid grid-cols-3 gap-6">
        <!-- First Name -->
        <div>
          <label class="block text-sm font-medium text-gray-700">First Name</label>
          <input type="text" placeholder="e.g., Avish" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-2" [value]="personalDetails.firstName">
        </div>

        <!-- Last Name -->
        <div>
          <label class="block text-sm font-medium text-gray-700">Last Name</label>
          <input type="text" placeholder="e.g., Shetty" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-2" [value]="personalDetails.lastName">
        </div>

        <!-- Email -->
        <div>
          <label class="block text-sm font-medium text-gray-700">Email</label>
          <input type="email" placeholder="e.g., satyam@example.com" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-2" [value]="personalDetails.email">
        </div>

        <!-- Country -->
        <div>
          <label class="block text-sm font-medium text-gray-700">Country you are planning to go?</label>
          <input type="text" placeholder="e.g., USA" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-2" [value]="personalDetails.targetCountry">
        </div>

        <!-- Degree -->
        <div>
          <label class="block text-sm font-medium text-gray-700">Which Degree?</label>
          <input type="text" placeholder="e.g., Postgraduate" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-2" [value]="personalDetails.targetDegree">
        </div>

        <!-- Course -->
        <div>
          <label class="block text-sm font-medium text-gray-700">Which Course?</label>
          <input type="text" placeholder="e.g., Bioengineering" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-2" [value]="personalDetails.targetCourse">
        </div>

        <!-- Profession -->
        <div>
          <label class="block text-sm font-medium text-gray-700">Profession?</label>
          <input type="text" placeholder="e.g., Student" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-2" [value]="personalDetails.profession">
        </div>

        <!-- Months of Experience -->
        <div *ngIf="personalDetails?.months_of_experience">
          <label class="block text-sm font-medium text-gray-700">Total Months of Experience</label>
          <input type="text" placeholder="e.g., 24" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-2" [value]="personalDetails.months_of_experience" readonly>
        </div>
      </div>

      <h2 class="text-xl font-semibold text-blue-500">Academic Details</h2>

        <!-- Undergraduate Marks Section -->
        <div *ngIf="personalDetails.academicHistory.undergraduateMarks" class="grid grid-cols-3 gap-6">
          <div>
            <label class="block text-sm font-medium text-gray-700">College Name</label>
            <input type="text" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-2" 
                  [value]="personalDetails.academicHistory.undergraduateMarks.undergradCollegeName">
          </div>

          <div>
            <label class="block text-sm font-medium text-gray-700">Percentage</label>
            <input type="text" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-2" 
                  [value]="personalDetails.academicHistory.undergraduateMarks.percentage">
          </div>

          <div>
            <label class="block text-sm font-medium text-gray-700">Degree</label>
            <input type="text" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-2" 
                  [value]="personalDetails.academicHistory.undergraduateMarks.degreeName">
          </div>

          <div>
            <label class="block text-sm font-medium text-gray-700">Backlogs</label>
            <input type="text" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-2" 
                  [value]="personalDetails.academicHistory.undergraduateMarks.degreeBacklogs">
          </div>
        </div>

        <!-- High School Marks Section -->
        <div *ngIf="personalDetails.academicHistory.highSchoolMarks" class="grid grid-cols-3 gap-6">
          <div>
            <label class="block text-sm font-medium text-gray-700">High School Name</label>
            <input type="text" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-2" 
                  [value]="personalDetails.academicHistory.highSchoolMarks.highSchoolName">
          </div>

          <div>
            <label class="block text-sm font-medium text-gray-700">Percentage</label>
            <input type="text" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-2" 
                  [value]="personalDetails.academicHistory.highSchoolMarks.percentage">
          </div>
        </div>


      <!-- Language Exam Section -->
      <h2 class="text-lg font-semibold text-blue-500 mt-10">Language Exam Taken</h2>
      <div class="grid grid-cols-2 gap-6" *ngIf="personalDetails?.entryTests?.length">
        <div>
          <label class="block text-sm font-medium text-gray-700">Test Type *</label>
          <input type="text" [value]="personalDetails.entryTests[0]?.testName" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-2" readonly>
        </div>

        <div *ngIf="personalDetails.entryTests[0]?.testScore">
          <label class="block text-sm font-medium text-gray-700">Test Score *</label>
          <input type="text" [value]="personalDetails.entryTests[0]?.testScore" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-2" readonly>
        </div>
      </div>

      <!-- Aptitude Exam Section -->
      <h2 class="text-lg font-semibold text-blue-500 mt-10">Aptitude Exam Taken</h2>
      <div class="grid grid-cols-2 gap-6" *ngIf="personalDetails?.entryTests?.length > 1">
        <div>
          <label class="block text-sm font-medium text-gray-700">Test Type *</label>
          <input type="text" [value]="personalDetails.entryTests[1]?.testName" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-2" readonly>
        </div>

        <div *ngIf="personalDetails.entryTests[1]?.testScore">
          <label class="block text-sm font-medium text-gray-700">Test Score *</label>
          <input type="text" [value]="personalDetails.entryTests[1]?.testScore" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-2" readonly>
        </div>
      </div>
    </form>
  </div>
</div>
