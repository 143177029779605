// 
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-student-register',
  templateUrl: './student-register.component.html',
  styleUrls: ['./student-register.component.scss']
})
export class StudentRegisterComponent implements OnInit {
  email: string = '';
  password: string = '';
  partnerUrl: string = '';

  constructor(
    private http: HttpClient, 
    private router: Router,
    private route: ActivatedRoute
  ) {}

  // ngOnInit() {
  //   this.route.params.subscribe(params => {
  //     this.partnerUrl = params['partnerUrl'];
  //   });
  // } og

  ngOnInit() {
    // Extract the partner URL from the current route
    this.partnerUrl = this.route.snapshot.paramMap.get('partnerUrl') || '';
    if (!this.partnerUrl) {
      // If no partner URL is provided, redirect to an error page or show a message
      // this.router.navigate(['/invalid-registration']);
      console.log('Invalid Registration')
    }
  }

  register() {
    const userData = {
      email: this.email,
      password: this.password,
    };

    this.http.post(`http://localhost:8000/api/student/register/${this.partnerUrl}/`, userData)
      .subscribe(
        response => {
          console.log('Registration successful', response);
          this.router.navigate(['/student-login'])
          // Handle successful registration (e.g., show a success message, navigate to login)
        }, 
        error => {
          console.error('Registration failed', error);
          // Handle registration error (e.g., show an error message)
        }
      );
  }
}