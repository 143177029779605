import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StudentService } from '../services/student-details.service';

@Component({
  selector: 'app-student-details',
  templateUrl: './student-details.component.html',
  styleUrls: ['./student-details.component.scss']
})
export class StudentDetailsComponent implements OnInit {
  studentDetails: any;
  studentId: number | null = null;
  wishlistItems: any[] = [];
  choiceDetails: any = {};

  constructor(private route: ActivatedRoute, private http: HttpClient, private studentService: StudentService, private router: Router,) {}

  ngOnInit(): void {
    const studentIdParam = this.route.snapshot.paramMap.get('id');
    console.log('Received studentId from route:', studentIdParam);

    if (studentIdParam) {
      this.studentId = +studentIdParam;
      console.log("good")
    } else {
      console.error('1111111  Student ID is null or undefined');
      return;
    }

    console.log('studentId after assignment:', this.studentId);

    if (this.studentId) {
      this.fetchStudentDetails();
      this.fetchWishlistItems();
    } else {
      console.error('22222  Student ID is still null or undefined');
      
    }
  }

  fetchStudentDetails(): void {
    this.studentService.getStudentDetails(this.studentId!).subscribe(
      (data: any) => {
        this.studentDetails = data.student_details;
        console.log('Student Details:', this.studentDetails);
      },
      (error) => {
        console.error('Error fetching student details', error);
      }
    );
  }

  fetchWishlistItems(): void {
    this.studentService.getStudentWishlist(this.studentId!).subscribe(
      (data: any) => {
        this.wishlistItems = data.wishlist;
        console.log('Wishlist Items:', this.wishlistItems);
      },
      (error) => {
        console.error('Error fetching student wishlist items:', error);
      }
    );
  }
}


