import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-partner-dashboard',
  templateUrl: './partner-dashboard.component.html'
})
export class PartnerDashboardComponent implements OnInit {
  students: any[] = [];

  constructor(private http: HttpClient, private router: Router) {}

  ngOnInit(): void {
    const partnerId = localStorage.getItem('partner_id');  
    const token = localStorage.getItem('access_token');  
    console.log(localStorage.getItem('partner_id'));
    console.log(localStorage.getItem('access_token'));

    if (!token) {
      console.error('No JWT token found in local storage');
      return;
    }

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    this.http.get(`http://localhost:8000/api/partner/dashboard/?partner_id=${partnerId}`, { headers })
  .subscribe(
    (data: any) => {
      this.students = data.students;
    },
    (error) => {
      if (error.status === 401) {
        console.error("Unauthorized access - perhaps the token is invalid or expired.");
      } else if (error.status === 403) {
        console.error("Forbidden - the partner ID might not match the user ID in the token.");
      } else {
        console.error("Error fetching students: ", error);
      }
    }
  );

  }

  viewStudentDetails(student: any, event: Event) {
    event.stopPropagation();  
  
    if (student && student.id) {
      const studentId = student.id;
      this.router.navigate([`/student/details/${studentId}`]);
    } else {
      console.error('Invalid student data, cannot navigate to details');
    }
  }
  
}
