import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-partner-register',
  templateUrl: './partner-register.component.html',
  styleUrls: ['./partner-register.component.scss']
})
export class PartnerRegisterComponent {
  name: string = '';
  email: string = '';
  password: string = '';
  successMessage: string | null = null;
  errorMessage: string | null = null;

  constructor(private http: HttpClient, private router: Router) { }

  registerPartner() {
    const partnerData = {
      name: this.name,
      email: this.email,
      password: this.password,
      
    };
    console.log(partnerData)
    this.http.post('http://127.0.0.1:8000/api/partner/register/', partnerData).subscribe({
      next: (response: any) => {
        this.successMessage = 'Partner registered successfully!';
        this.errorMessage = null;
        this.router.navigate(['/partner-login']);
      },
      error: (error) => {
        this.errorMessage = 'Failed to register partner. Please try again.';
        this.successMessage = null;
      }
    });
  }

  generateCustomUrl(name: string): string {
    return name.trim().toLowerCase().replace(/ /g, '-') + '-' + Date.now();
  }
}
