import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeAffiliations'
})
export class RemoveAffiliationsPipe implements PipeTransform {
  transform(value: string): string {
    // Define the patterns to be removed
    const unwantedPatterns = ['(Shorelight)', '(INTO)', '(Study Group)', '(Kaplan)'];
    
    // Replace each unwanted pattern from the university name
    unwantedPatterns.forEach(pattern => {
      value = value.replace(pattern, '').trim();
    });

    return value;
  }
}
