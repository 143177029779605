<div class="flex flex-wrap justify-start items-center bg-white p-4 shadow-md rounded-lg space-x-4 mb-4 ml-14">
    <div>
      <label class="block text-gray-700 text-sm font-bold mb-2">Country</label>
      <select [(ngModel)]="selectedCountry" class="p-2 border rounded-lg w-full">
        <option value="">Select Country</option>
        <option *ngFor="let country of countries" [value]="country">{{ country }}</option>
      </select>
    </div>
    
    <div>
      <label class="block text-gray-700 text-sm font-bold mb-2">Course</label>
      <select [(ngModel)]="selectedCourse" class="p-2 border rounded-lg w-full">
        <option value="">Select Course</option>
        <option *ngFor="let course of courses" [value]="course">{{ course }}</option>
      </select>
    </div>
    
    <div>
      <label class="block text-gray-700 text-sm font-bold mb-2">Study Level</label>
      <select [(ngModel)]="selectedStudyLevel" class="p-2 border rounded-lg w-full">
        <option value="">Select Study Level</option>
        <option value="Undergraduate">Undergraduate</option>
        <option value="Postgraduate">Postgraduate</option>
      </select>
    </div>
  
    <div class="flex space-x-4">
      <button (click)="onApplyFilters()" class="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-700">
        Apply
      </button>
      
    </div>
  </div>
  