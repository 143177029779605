import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PartnerService } from '../services/partner.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private partnerService:PartnerService,
  ) { }

  puid: string = "";
  partnerLogo: string = ''; 

  fetchPartnerLogo(puid: string): void {
    this.partnerService.getPartnerLogo(puid).subscribe(
      (response) => {
        this.partnerLogo = response.logo; 
      },
      (error) => {
        console.error('Error fetching partner logo:', error);
      }
    );
  }

  // Navigate to the registration page
  navigateToRegister() {
    if (this.puid) {
      this.router.navigate(['/user-info/' + this.puid]);
    } else {
      this.router.navigate(['/user-info']);
    }
  }

  // Navigate to the login page
  navigateToLogin() {
    if (this.puid) {
      this.router.navigate(['/student-login/' + this.puid]);
    } else {
      this.router.navigate(['/student-login']);
    }
  }

  ngOnInit(): void {
    const puid = this.activatedRoute.snapshot.paramMap.get('puid')
    if (puid) {
      this.puid = puid;
      console.log(puid,54);
      this.fetchPartnerLogo(puid);
    }
  }
}
