import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-partner-login',
  templateUrl: './partner-login.component.html',
  styleUrls: ['./partner-login.component.scss']
})
export class PartnerLoginComponent {
  credentials = {
    emailOrMobile: '',
    password: ''
  };
  errorMessage: string | null = null;

  constructor(private http: HttpClient, private router: Router) {}

  clearTokens() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  }

  login() {
    this.clearTokens();
    console.log(this.credentials)
    this.http.post('http://localhost:3000/api/users/login', this.credentials).subscribe({
      next: (response: any) => {
        console.log(response, 29)
        localStorage.setItem('partner_id', response.partner_id); 
        localStorage.setItem('access_token', response.access); 
        localStorage.setItem('refresh_token', response.refresh); 
        this.router.navigate(['/partner-dashboard']);
      },
      error: (error) => {
        this.errorMessage = 'Invalid email or password';
      }
    });
  }
}
