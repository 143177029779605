
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'; // Removed HttpHeaders import
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PartnerService { 
  private baseUrl: string = environment.apiBaseUrl;
  constructor(private http: HttpClient) {}

  getPartnerLogo(puid: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/partner-details/get-partner-logo/${puid}`);
  }

  // loginPartner(credentials: any): Observable<any> {
  //   return this.http.post(`${this.baseUrl}/token/`, credentials);
  // }

  // getPartnerStudents(): Observable<any> {
  //   return this.http.get(`${this.baseUrl}/partner/dashboard/`); // Removed headers
  // }
}

