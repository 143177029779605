import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OpenAIService {
  private apiUrl = 'https://api.openai.com/v1/chat/completions'; 
  private apiKey = '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'; 

  constructor(private http: HttpClient) {}

  getJobDetails(prompt: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.apiKey}`
    });

    const body = {
      model: 'gpt-4o', 
      messages: [{ role: 'user', content: prompt }],
      max_tokens: 500,
      temperature: 0.7
    };

    return this.http.post<any>(this.apiUrl, body, { headers: headers });
  }
}
