<div class="container mx-auto">
  <h1 class="text-3xl font-bold my-4">Partner Dashboard</h1>
  <table class="min-w-full bg-white">
    <thead>
      <tr>
        <th class="py-2">Student ID</th>
        <th class="py-2">Email</th>
        <th class="py-2">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let student of students">
        <td>{{ student.id }}</td>
        <td>{{ student.email }}</td>
        <td>
          <a (click)="viewStudentDetails(student, $event)">View Details</a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
