<div class="header2">
  <div>
    <img *ngIf="partnerLogo" [src]="partnerLogo" alt="Partner Logo" class="w-[176px] h-[34px]" /> 
  </div>
  <div class="flex">

      <!-- <div class="relative inline-block mr-10">
        <img 
          src="assets/icons/heart.svg" 
          alt="Wishlist Heart Icon" 
          (click)="viewWishlist()"
          class="cursor-pointer w-8 h-8 hover:opacity-75"
        />
        <div class="absolute top-0 right-0 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs font-bold">

          {{ wishlistLength }}
        </div>
      </div>
       -->
    <!-- <div class="flex">
      <img
        src="assets/icons/profile.svg" 
        alt="Profile Photo"
        (click)="viewProfile()"
        class="cursor-pointer w-8 h-8 hover:opacity-75"
      >
      <p class="ml-2 font-poppins font-[400px] text-black text-[12px]">{{this.initialFilter.firstName}}</p>
    </div> -->
  </div>
</div>

<div *ngIf="course" class="p-4">
  <!-- Header Section -->
  <div class="flex items-center mb-4">
    <button (click)="prevStep()" class="text-gray-600 mb-4 mr-4">
      <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path>
      </svg>
    </button>
    <h4 class="mb-2 font-poppins font-[500px] text-[16px]">Universities > {{ course.university | removeAffiliations  }}</h4>
  </div>

  <!-- Main Content Grid -->
  <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
    
    <!-- Left Column: University Information -->
    <div class="bg-white shadow rounded-[22px] p-[16px] border border-[#BCBEBF] w-[403px] h-[483px] flex flex-col justify-between">
      <p class="text-xl font-bold mb-2 font-poppins">{{ course.university | removeAffiliations }}</p>
      <p class="text-sm mb-4 font-poppins text-[#00558C]">{{ course.course_name }}</p>

      <hr class="border-t border-black-600">

      <div class="space-y-2 font-poppins">

        <div class="flex items-center space-x-2 justify-between font-poppins">
          <div class="flex">
            <img src="assets/icons/map.svg" alt="Country Icon" class="w-5 h-5">
            <p class="text-gray-500 ml-1">Country</p>
          </div>
          <div>
            <p class="ml-auto text-[#0F4B87] font-[500px]">{{ course.country === 'US' ? 'USA' : course.country }}</p>
          </div>
        </div>

        <div class="flex items-center space-x-2 justify-between">
          <div class="flex">
            <img src="assets/icons/money.svg" alt="Country Icon" class="w-5 h-5">
            <p class="text-gray-500 ml-1">Duration:</p>
          </div>
          <div>
            <p class="ml-auto text-[#0F4B87]">{{ course.duration }}</p>
          </div>
        </div>

        <div class="flex items-center space-x-2 justify-between">
          <div class="flex">
            <img src="assets/icons/calendar-icon.svg" alt="Country Icon" class="w-5 h-5">
            <p class="text-gray-500 ml-1">Tuition Fees</p>
          </div>
          <div>
            <p class="ml-auto text-[#0F4B87]"> {{ course.country === 'US' ? '$' : (course.country === 'Germany' ? '€' : (course.country === 'UK' ? '£' : '')) }}{{ course.yearly_tuition_fees }} / year</p>
          </div>
        </div>

        <div class="flex items-center space-x-2 justify-between">
          <div class="flex">
            <img src="assets/icons/teacher.svg" alt="Country Icon" class="w-5 h-5">
            <p class="text-gray-500 ml-1">Degree</p>
          </div>
          <div>
            <p class="ml-auto text-[#0F4B87]"> {{ course.study_level }}</p>
          </div>
        </div>


        <div class="flex items-center space-x-2 justify-between">
          <div class="flex">
            <img src="assets/icons/receipt-text.svg" alt="Country Icon" class="w-5 h-5">
            <p class="text-gray-500 ml-1">Application Fees</p>
          </div>
          <div>
            <p class="ml-auto text-[#0F4B87]">
              <ng-container *ngIf="course.application_fee !== 'Waiver' && course.application_fee !== null">
                {{
                  course.country === 'US' ? '$' :
                  course.country === 'Germany' ? '€' :
                  course.country === 'UK' ? '£' : ''
                }}
              </ng-container>
              {{ course.application_fee !== null ? course.application_fee : 'N/A' }}
            </p>
          </div>
        </div>
        

        <div class="flex items-center space-x-2 justify-between">
          <div class="flex">
            <img src="assets/icons/map.svg" alt="Country Icon" class="w-5 h-5">
            <p class="text-gray-500 ml-1 ">Location</p>
          </div>
          <div>
            <p class="ml-auto text-[#0F4B87]"> {{ course.campus }}, {{ course.country }}</p>
          </div>
        </div>
        
      </div>

      <div class="action-buttons font-[400px] font-poppins text-xs">
        <a 
          href="{{ course.website }}" 
          target="_blank" 
          class="website-button flex items-center space-x-2 px-4 py-2 rounded" 
          (click)="$event.stopPropagation()">
          <img src="assets/icons/global.svg" alt="Earth Icon" class="button-icon w-4 h-4 mr-1" />
          Visit Website
        </a>
      
        <button 
          class="wishlist-button font-[400px] font-poppins text-xs" 
          [ngClass]="{ 'active': course.isInWishlist }" 
          (click)="addToWishlist(course, $event); $event.stopPropagation()">
          <img [src]="course.isInWishlist ? 'assets/icons/active-heart.svg' : 'assets/icons/heart.svg'" alt="Heart Icon" class="button-icon" />
          {{ course.isInWishlist ? 'Shortlisted' : 'Shortlist' }}
        </button>
      </div>
      
    </div>

    <!-- Right Column: Entry Requirements -->
    <div class="bg-white rounded-lg p-1 col-span-2">
      <div class="p-4 border border-gray-200 rounded-lg">
      <h3 class="text-lg font-semibold mb-2 font-poppins text-[#00558C] rounded-[16px]">Entry Requirements</h3>
      <div class="grid grid-cols-2 gap-4">
        <!-- English Test Scores -->
        <div class="text-left">
          <p class="font-poppins text-[#2F455C]">English Test</p>
          <ng-container *ngFor="let test of course.test_scores" class="font-poppins">
            <p class="font-[500px]"*ngIf="test.test_name === 'IELTS' || test.test_name === 'TOEFL' || test.test_name === 'PTE' || test.test_name === 'DET'">
              {{ test.test_name }}: {{ test.min_score || 'N/A' }} /
              <ng-container [ngSwitch]="test.test_name">
                <span *ngSwitchCase="'IELTS'">9</span>
                <span *ngSwitchCase="'TOEFL'">120</span>
                <span *ngSwitchCase="'PTE'">90</span>
                <span *ngSwitchCase="'DET'">160</span>
              </ng-container>
            </p>
          </ng-container>
        </div>
        
        <!-- Aptitude Test Scores -->
        <div class="text-left font-poppins">
          <p class="text-[#2F455C]">Aptitude Test</p>
          <ng-container *ngFor="let test of course.test_scores">
            <p class="font-[500px]" *ngIf="test.test_name === 'GRE' || test.test_name === 'GMAT'">
              {{ test.test_name }}: {{ test.min_score || 'N/A' }} /
              <ng-container [ngSwitch]="test.test_name">
                <span *ngSwitchCase="'GRE'">340</span> 
                <span *ngSwitchCase="'GMAT'">800</span> 
              </ng-container>
            </p>
          </ng-container>
        </div>
      </div>
    
      <!-- Entry Requirements Note -->
      <p class="mt-4 font-poppins text-[#212529]"><strong>Note:</strong><br>{{ course.entry_requirements }}</p>
      <!-- <div class="mt-2 font-poppins text-[#212529]"><p>Upto {{ course.backlog_range }} backlogs are allowed.</p></div> -->
    
    </div>
    <hr class="my-4 border-t border-black-600">
      <!-- Fees and Scholarships Section -->
      <div class="p-4 border border-gray-200 rounded-[16px]">
      <h3 class="text-[#00558C] font-semibold font-poppins text-lg mb-4">Fees and Scholarships</h3>

      <!-- Yearly Tuition Fees -->
      <div class="flex mb-2">
        <p class="text-gray-500 font-poppins">Yearly Tuition Fees: </p>
        <p class="font-poppins ml-1">
          <ng-container *ngIf="course.yearly_tuition_fees !== null">
            {{ course.country === 'US' ? '$' : (course.country === 'Germany' ? '€' : (course.country === 'UK' ? '£' : '')) }}
          </ng-container>
          {{ course.yearly_tuition_fees }}</p>
      </div>

      <!-- Application Deadline -->
      <div class="flex mb-2">
        <p class="text-gray-500 font-poppins">Application Deadline: </p>
        <p class="font-poppins ml-1"> {{ course.application_deadline }}
        </p>
      </div>

      <!-- Scholarship -->
      <div class="flex mb-2">
        <p class="text-gray-500 font-poppins">Scholarship: </p>
        <p class="font-poppins ml-1"> {{ course.scholarship_available }}</p>
      </div>

      <!-- Scholarship Details -->
      <div class="flex mb-2">
        <p class="text-gray-500 font-poppins">Scholarship Details: </p>
        <p class="font-poppins ml-1"> {{ course.scholarship_details ? course.scholarship_details : '-' }}</p>
      </div>

      <div class="flex mb-2">
        <p class="text-gray-500 font-poppins">English Proficiency Exam Waiver : </p>
        <p class="font-poppins ml-1"> {{ course.english_proficiency_required }}</p>
      </div>
    </div>

    <hr class="my-4 border-t border-black-600 rounded-[16px]">

      <div class="p-4 border border-gray-200 rounded-lg">
        <h3 class="text-[#00558C] font-semibold font-poppins text-lg mb-4">Rankings</h3>
        <div class="flex mb-2">
          <p class="text-gray-500 font-poppins">Webometrics National Ranking: </p>
          <p class="font-poppins ml-2"> {{ course.webometrics_national_ranking || 'NA' }}</p>
        </div>
        <div class="flex mb-2">
          <p class="text-gray-500 font-poppins">Webometrics World Ranking: </p>
          <p class="font-poppins ml-2"> {{ course.webometrics_world_ranking ||  'N/A' }}</p>
        </div>
        <div class="flex mb-2">
          <p class="text-gray-500 font-poppins">US News Ranking: </p>
          <p class="font-poppins ml-2"> {{ course.us_news_ranking || 'N/A' }}</p>
        </div>
        
        <div class="flex mb-2">
          <p class="text-gray-500 font-poppins">QS University Ranking: </p>
          <p class="font-poppins ml-2"> {{ course.qs_ranking || 'N/A' }}</p>
        </div>
      </div>

      <hr class="my-4 border-t border-black-600 rounded-[16px]">
      <!-- Job Opportunities Section -->
      <div class="bg-white shadow p-4 mt-4 rounded-[16px] cursor-pointer" (click)="fetchJobDetails()">
        <h3 class="text-lg mb-2 font-poppins text-[#00558C] font-semibold">Job Opportunities ></h3>
      
        <!-- Show loader if loading -->
        <div *ngIf="isLoading" class="flex items-center">
          <div class="loader mr-2"></div> 
          <p>Loading job details...</p>
        </div>
      
        <!-- Job details content -->
        <div *ngIf="jobDetails" class="mt-4"> 
          <h4 class="font-poppins">Job Information:</h4>
          <ul class="list-disc ml-5">
            <li *ngFor="let job of jobDetails" class="mb-2">
              <p class="font-poppins font-semibold">Job Title: {{ job.jobTitle }}</p>
              <p class="font-poppins">Salary: {{ job.salaryPerYear }}</p>
            </li>
          </ul>
          <p *ngIf="jobDetailsSource" class="mt-4 font-poppins">
            Source: <a href="{{ jobDetailsSource }}" class="text-blue-500 underline" target="_blank">{{ jobDetailsSource }}</a>
          </p>
        </div>
      
        <!-- If no job details are available -->
        <div *ngIf="!jobDetails && jobDetails !== null && !isLoading" class="mt-4">
          <p class="font-poppins text-red-500">No job information available at this time.</p>
        </div>
      </div>
      
    </div>
    
  </div>
</div>
