<div class="flex justify-center items-center min-h-screen bg-gray-100">
    <div class="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
      <h2 class="text-2xl font-bold mb-6 text-center">Partner Login</h2>
      
      <form (ngSubmit)="login()">

        <div class="mb-4">
          <label for="email" class="block text-sm font-medium text-gray-700">email</label>
          <input [(ngModel)]="credentials.emailOrMobile" name="email" type="text" 
                 class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500" required>
        </div>
  
        <div class="mb-6">
          <label for="password" class="block text-sm font-medium text-gray-700">Password</label>
          <input [(ngModel)]="credentials.password" name="password" type="password" 
                 class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500" required>
        </div>
  
        <div>
          <button type="submit" class="w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            Login
          </button>
        </div>
      </form>
  
      <p *ngIf="errorMessage" class="text-red-500 mt-4">{{ errorMessage }}</p>
    </div>
  </div>
  