import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { UserInfoPageComponent } from './user-info-page/user-info-page.component'; 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResultsComponent } from './results/results.component';
// import { CourseResultService } from './course-results.service';
import { UniversityDetailComponent } from './university-detail/university-detail.component';
import { WishlistComponent } from './wishlist/wishlist.component';
import { WishlistPageComponent } from './wishlist-page/wishlist-page.component';
import { FilterBarComponent } from './filter-bar/filter-bar.component';
import { StudentLoginComponent } from './student-login/student-login.component';
import { StudentRegisterComponent } from './student-register/student-register.component';
import { JwtModule } from '@auth0/angular-jwt';
import { RouterModule } from '@angular/router';
import { PartnerLoginComponent } from './partner-login/partner-login.component';
import { PartnerDashboardComponent } from './partner-dashboard/partner-dashboard.component';
import { PartnerService } from './services/partner.service';
import { PartnerRegisterComponent } from './partner-register/partner-register.component';
import { StudentDetailsComponent } from './student-details/student-details.component';
import { FormlyModule } from '@ngx-formly/core';  
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { PersonalDetailsComponent } from './personal-details/personal-details.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormlyFieldIntlTelInputComponent } from './formly-field-intl-tel-input/formly-field-intl-tel-input.component';
import { RemoveAffiliationsPipe } from './remove-affiliations.pipe';
import { ConnectCounsellorComponent } from './connect-counsellor/connect-counsellor.component';
import { ProfileHelperComponent } from './components/profile-helper/profile-helper.component';



export function tokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    UserInfoPageComponent,
    ResultsComponent,
    UniversityDetailComponent,
    WishlistComponent,
    WishlistPageComponent,
    FilterBarComponent,
    StudentLoginComponent,
    StudentRegisterComponent,
    PartnerLoginComponent,
    PartnerDashboardComponent,
    PartnerRegisterComponent,
    StudentDetailsComponent,
    PersonalDetailsComponent,
    FormlyFieldIntlTelInputComponent,
    RemoveAffiliationsPipe,
    ConnectCounsellorComponent,
    ProfileHelperComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,  
    FormlyModule.forRoot({
      types: [
        { name: 'intl-tel-input', component: FormlyFieldIntlTelInputComponent },
      ],
    }),
    FormlyBootstrapModule,
    NgxIntlTelInputModule,
    BrowserAnimationsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ['127.0.0.1:8000'], 
        disallowedRoutes: ['http://127.0.0.1:8000/api/token/'], 
      },
    }),
    RouterModule  
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [PartnerService],
  bootstrap: [AppComponent]
})
export class AppModule { }
