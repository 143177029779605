import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { PartnerService } from '../services/partner.service';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms'; // Import for form control
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input-gg';


interface UserResponse {
  isExistingUser: boolean;
}

@Component({
  selector: 'app-student-login',
  templateUrl: './student-login.component.html',
  styleUrls: ['./student-login.component.scss'],
})
export class StudentLoginComponent {
  phoneForm: FormGroup;
  mobile: string = '';
  // otpDigits: string[] = ['', '', '', '', '', '']; // Array to store individual OTP digits
  isOtpMode: boolean = false;
  userType: string = 'student';
  isExistingUser: boolean = false;
  countdown: number = 59; 
  countdownInterval: any;
  puid: string = "";
  partnerLogo: string = ''; 
  partnerConsent: string = '';
  public SearchCountryField = SearchCountryField;
  public CountryISO = CountryISO;
  public PhoneNumberFormat = PhoneNumberFormat;

  
  constructor(private http: HttpClient,
     private router: Router,
     private activatedRoute: ActivatedRoute,
     private partnerService:PartnerService,
     private formBuilder: FormBuilder
  ) {
    this.phoneForm = this.formBuilder.group({
      mobile: [undefined, [Validators.required]],  // ngx-intl-tel-input handles phone validation
      otpDigits: this.formBuilder.array([
        new FormControl('', Validators.required),
        new FormControl('', Validators.required),
        new FormControl('', Validators.required),
        new FormControl('', Validators.required),
        new FormControl('', Validators.required),
        new FormControl('', Validators.required),
      ]),
    });
  }
  

  ngOnInit(): void {
    const puid = this.activatedRoute.snapshot.paramMap.get('puid')
    if (puid) {
      this.puid = puid;
      this.fetchPartnerLogo(puid);
    }
    localStorage.removeItem('access_token');
    localStorage.removeItem('appliedFilters');
    localStorage.removeItem('initialFilter');
    localStorage.removeItem('loglevel');
    localStorage.removeItem('results');
    localStorage.removeItem('uid');
    localStorage.removeItem('currentFilter');
    localStorage.removeItem('created');
  }

  fetchPartnerLogo(puid: string): void {
    this.partnerService.getPartnerLogo(puid).subscribe(
      (response) => {
        this.partnerLogo = response.data.company.logo; 
        this.partnerConsent = response.data.company.consent;
      },
      (error) => {
        console.error('Error fetching partner logo:', error);
        this.partnerConsent = "By providing the mobile number, I agree to override the registry on NCPR/NDNC and authorize us to connect me and send relevant notifications via call, Email, SMS and WhatsApp.";
      }
    );
  }

  get otpDigits(): FormArray {
    return this.phoneForm.get('otpDigits') as FormArray;
  }

  // Function to combine the OTP digits into a single string
  getOtpString(): string {
    return this.otpDigits.controls.map(control => control.value).join('');
  }

  startOtpMode() {
    this.isOtpMode = true;
    this.startCountdown(); // Start the countdown
  }

  resendOtp() {
    // Reset the countdown
    this.countdown = 59;
    this.startCountdown(); // Restart countdown

    // Logic to resend OTP, e.g., making an HTTP call to your backend
    this.sendMobileNumber();
  }

  editMobileNumber() {
    // Stop the countdown
    clearInterval(this.countdownInterval);
    
    // Switch back to mobile input mode
    this.isOtpMode = false;
  }


  moveFocus(index: number) {
    const currentInput = document.querySelectorAll('.otp-box')[index] as HTMLInputElement;
    const nextInput = document.querySelectorAll('.otp-box')[index + 1] as HTMLInputElement;
    
    if (currentInput && currentInput.value !== '' && nextInput) {
      nextInput.focus(); // Move focus to the next input
    }
  }


    // Function to handle the countdown
  startCountdown() {
    this.countdown = 59; // Reset countdown time
    clearInterval(this.countdownInterval);
    this.countdownInterval = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        clearInterval(this.countdownInterval);
      }
    }, 1000);
  }
  
  editMobile() {
    this.isOtpMode = false;
    clearInterval(this.countdownInterval);
  }

  sendMobileNumber() {
    const userType = 'student';
    const puid = this.activatedRoute.snapshot.paramMap.get('puid');
    const phoneNumber = this.phoneForm.value.mobile.e164Number;
    this.http
      // .post<UserResponse>('http://127.0.0.1:3000/api/users', {
      .post<UserResponse>('https://dev.foreignadmits.app/api/users', {
        mobile: phoneNumber,
        type: userType,
        usersAvailOtp: true,
        puid: puid
      })
      .subscribe(
        (response: UserResponse) => {
          console.log('Mobile number sent successfully', response);
          this.isExistingUser = response.isExistingUser;
          this.isOtpMode = true;
        },
        (error) => {
          console.error('Error sending mobile number', error);
        }
      );
      this.startOtpMode();
  }

  verifyOtpAndLogin() {
    const otp = this.getOtpString();
    const puid = this.activatedRoute.snapshot.paramMap.get('puid');
    console.log(this.phoneForm.value.mobile.e164Number,147)
    console.log(otp,148)
    this.http
      // .post('http://127.0.0.1:3000/api/users/login-with-mobile-otp', {
      .post('https://dev.foreignadmits.app/api/users/login-with-mobile-otp', {
        mobile: this.phoneForm.value.mobile.e164Number,
        otp: otp 
      })
      .subscribe(
        (response: any) => {
          console.log(response, 57);
          localStorage.setItem('access_token', response.id);
          localStorage.setItem('uid', response.userDetails.uid);

          if (localStorage.getItem('savedFilters')) {
            localStorage.removeItem('savedFilters');
            console.log('Saved filters removed from localStorage');
          }

          if (localStorage.getItem('studentChoices')) {
            localStorage.removeItem('studentChoices');
            console.log('Student choices removed from localStorage');
          }

          if (this.isExistingUser) {
            if (puid) {
              this.router.navigate(['/personal-details/' + puid]);
            } else {
              this.router.navigate(['/personal-details']);
            }
          } else {
            if (puid) {
              this.router.navigate(['/user-info/' + puid]);
            } else {
              this.router.navigate(['/user-info']);
            }
          }
        },
        (error) => {
          console.error('Login failed', error);
        }
      );
  }

  login() {
    if (this.isOtpMode) {
      this.verifyOtpAndLogin();
    } else {
      this.sendMobileNumber();
    }
  }
}
