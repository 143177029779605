import { Component, OnInit,  HostListener,ElementRef  } from '@angular/core';
import { WishlistService } from '../wishlist.service';
import { HttpHeaders } from '@angular/common/http';
import { ActivatedRoute,Router } from '@angular/router';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
import * as XLSX from 'xlsx';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-wishlist-page',
  templateUrl: './wishlist-page.component.html',
  styleUrls: ['./wishlist-page.component.scss']
})
export class WishlistPageComponent implements OnInit {
  wishlist: any[] = [];
  isDownloadOptionsVisible = false;
  constructor(public wishlistService: WishlistService, private activatedRoute: ActivatedRoute, private router: Router, private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.loadWishlist();
  }

  loadWishlist() {
    const token = localStorage.getItem('access_token');
    const uid = localStorage.getItem('uid');

    if (!uid) {
      console.error('No UID found in local storage');
      throw new Error('No UID found in local storage');
    }
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'uid': uid  
    });

    this.wishlistService.getWishlist(headers).subscribe(
      (response: any) => { 
        console.log(response,37);
        this.wishlist = response.data;
      },
      (error) => {
        console.error('Error fetching wishlist:', error);
      }
    );
  }
  
  removeFromWishlist(course: any) {
    const courseId = course.courseId; 
    const token = localStorage.getItem('access_token');
    const uid = localStorage.getItem('uid');
  
    if (!uid) {
      console.error('No UID found in local storage');
      return;
    }
  
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'uid': uid
    });
  
    this.wishlistService.removeFromWishlist(courseId, headers).subscribe(
      (response) => {
        console.log('Course removed from wishlist:', response);
        this.wishlist = this.wishlist.filter(item => item.courseId !== courseId); 
      },
      (error) => {
        console.error('Error removing from wishlist:', error);
      }
    );
  }

  formatApplicationFee(course: any): string {
    const currencySymbol = course.country === 'US' ? '$' :
                           course.country === 'Germany' ? '€' :
                           course.country === 'UK' ? '£' : '';
  
    if (course.applicationFees === 'Waiver') {
      return 'Waiver';
    } else if (course.applicationFees !== null && course.applicationFees !== undefined && !isNaN(Number(course.applicationFees))) {
      return `${currencySymbol} ${course.applicationFees}`;
    } else {
      return 'N/A';
    }
  }

  prevStep(){
    const puid = this.activatedRoute.snapshot.paramMap.get('puid');
    console.log(puid,75)

    if(puid){
      this.router.navigate(['/results/'+puid]);
    }
    else{
      this.router.navigate(['/results']);
    }
  }
  
  downloadPDF() {
    const doc = new jsPDF();
    doc.setFontSize(18);
    doc.text('Your Shortlisted Universities', 14, 15);
  
    const rows = this.wishlist.map((course) => {
      const currencySymbol = course.country === 'US' ? '$' :
                             course.country === 'Germany' ? '€' :
                             course.country === 'UK' ? '£' : '';
  
      const applicationFeesDisplay = 
        course.applicationFees === "Waiver" ? "Waiver" : 
        course.applicationFees !== null && !isNaN(course.applicationFees) ? `${currencySymbol}${course.applicationFees}` : 
        'N/A';
  
      return [
        course.universityName || 'N/A',
        course.courseName || 'N/A',
        course.country || 'N/A',
        `${currencySymbol}${course.tuitionFees || 'N/A'}/year`,
        course.courseDuration || 'N/A',
        course.intakeSeason || 'N/A',
        applicationFeesDisplay
      ];
    });
  
    const headers = [['University', 'Course', 'Country', 'Tuition Fees', 'Duration', 'Intake Season', 'Application Fees']];
  
    (doc as any).autoTable({
      head: headers,
      body: rows,
      startY: 20, 
      theme: 'grid',
      headStyles: { fillColor: [41, 128, 185] },
      margin: { top: 10 }
    });
  
    // Save the PDF
    doc.save('wishlist.pdf');
  }
  


  toggleDownloadOptions() {
    this.isDownloadOptionsVisible = !this.isDownloadOptionsVisible;
  }

    // Close dropdown if clicked outside
  @HostListener('document:click', ['$event'])
  handleOutsideClick(event: Event) {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.isDownloadOptionsVisible = false;
    }
  }


  downloadExcel() {
    const wishlistData = this.wishlist.map(item => {
      // Extract data and prepare formatted output for Excel
      const { _id, courseId, tuitionFees, applicationFees, country, ...rest } = item;
  
      // Determine the currency symbol based on the country
      const currencySymbol = country === 'US' ? '$' :
                             country === 'Germany' ? '€' :
                             country === 'UK' ? '£' : '';
  
      // Format tuition fees with currency symbol
      const formattedTuitionFees = tuitionFees ? `${currencySymbol} ${tuitionFees}` : 'N/A';
  
      // Format application fees based on conditions
      const formattedApplicationFees = 
        applicationFees === 'Waiver' ? 'Waiver' :
        (applicationFees !== null && applicationFees !== undefined && !isNaN(Number(applicationFees))) ? `${currencySymbol} ${applicationFees}` : 'N/A';
  
      return {
        courseName: rest.courseName,
        universityName: rest.universityName,
        courseDuration: rest.courseDuration,
        degree: rest.degree,
        intakeSeason: rest.intakeSeason,
        location: rest.location,
        country: country, // Include country in the Excel data
        tuitionFees: formattedTuitionFees,
        applicationFees: formattedApplicationFees,
      };
    });
  
    // Generate the Excel file
    const worksheet = XLSX.utils.json_to_sheet(wishlistData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Wishlist');
    XLSX.writeFile(workbook, 'wishlist.xlsx');
  }
  
}
