import { Component, OnInit, EventEmitter, Output, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { HttpClient, HttpErrorResponse , HttpHeaders} from '@angular/common/http';
import { WishlistService } from '../wishlist.service';
import { DataCacheService } from '../services/data-cache.service';
import { PartnerService } from '../services/partner.service';
import { environment } from '../../environments/environment';
import { ConnectCounsellorComponent } from '../connect-counsellor/connect-counsellor.component';


function parseDuration(duration: string): number {
  const match = duration.match(/\d+/); 
  return match ? parseInt(match[0], 10) : 0; 
}


@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})


export class ResultsComponent implements OnInit {
  @ViewChild('connectCounsellor') connectCounsellor!: ConnectCounsellorComponent;
  puid: string = "";
  private baseUrl: string = environment.apiBaseUrl;
  @Output() filterApplied: EventEmitter<any> = new EventEmitter<any>();

  config: any[] = [];
  totalUniversities: number = 0;
  message: string = '';
  wishlistLength: number = 0; 
  showTooltip: boolean[] = [];
  isLoading: boolean = true;
  wishlist: any[] = [];
  partnerLogo: string = ''; 
  
  availableSpecializations: string[] = [
    "Aeronautical Engineering","Accounting","Bioengineering", 
    "Business Administration", "Business Analytics", "Chemical Engineering", 
    "Civil Engineering", "Computer Science", "Cyber Security", "Data Science", "Economics", "Electrical Engineering", "Science",
    "Energy Science and Technology",  "Fashion", "Finance", "Games Design",
    "Human Resources Management", "Information Technology", "Industrial Design", 
    "International Trade", "Investment & Finance", "Management","Mathematics", "Mechanical Engineering", 
    "National Security Studies", "Physics", "Plastics Engineering Technology", "Polymer and Fiber Engineering", 
    "Quantitative Finance", "Robotics Engineering", "Sports Marketing", "Marketing", 
  ];

    // Selected specialization
  selectedSpecialization: string = '';

    // Search term for filtering specializations
  specializationSearchTerm: string = '';
  isSpecializationDropdownOpen: boolean = false;

  isSortDropdownOpen: boolean = false;
  sortOptionLabel: string = 'Tuition Fees: High to Low';

  filteredConfig: any[] = []; // Filtered list of courses to display
  isScholarshipFilterEnabled: boolean = false;
  isApplicationFeeWaiverEnabled: boolean = false;
  isEnglishTestWaiverEnabled: boolean = false; 
  isLoanOfferEnabled: boolean = false;


  courseDuration: string[] = [
    "12 Months", "18 Months", "24 Months", "36 Months", "48 Months",
    "32 Months", "42 Months", "30 Months", "9 Months", 
    "47 Months", "24 Months", "6 Months", "36 Months", "15 Months", 
    "22 Months", "27 Months", "39 Months", "20 Months", 
    "48 Months", "54 Months", "28 Months", "17 Months", "18 Months", 
    "16 Months", "25 Months", "10 Months", "60 Months", "19 Months", 
    "12 Months", "14 Months", "72 Months", "11 Months", "13 Months", 
    "21 Months", "23 Months"
  ];

  countryOptions: { name: string, flagUrl: string }[] = [
    { name: 'US', flagUrl: 'https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1729754442825United%20States%20of%20America%201.png' },
    { name: 'UK', flagUrl: 'https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1729754474318UK-flag.png' },
    { name: 'Canada', flagUrl: 'https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1729754513112Canada-flag.png' },
    { name: 'Germany', flagUrl: 'https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1729754544994germany-flag.png' },
    { name: 'Australia', flagUrl: 'https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1729754573315Australia%201.png' }
  ];
  
  // public currentPage: number = 1;
  // public itemsPerPage: number = 9;
  isFilterDrawerOpen: boolean = false;
  isWishlistProcessed: boolean = false;
  
  // Initialize filter object
  initialFilter: any = {
    country: '',
    studyLevel: '',
    course: '',
    tuitionFees: 50000, 
    backlogs: 0,
    scholarshipAvailable: false,
    englishTestWaiver: false,
    applicationFeeWaiver: false
  };

  currentFilter: any = { ...this.initialFilter }; 

  constructor(private router: Router, 
    private http: HttpClient,
    public wishlistService: WishlistService,
    private activatedRoute: ActivatedRoute,
    private partnerService:PartnerService,
    private dataCacheService: DataCacheService) 
  {
    const navigation = this.router.getCurrentNavigation();
    this.config = navigation?.extras?.state?.['data'] || [];
    this.totalUniversities = this.config.length;
    this.showTooltip = new Array(this.config.length).fill(false);
  }

  ngOnInit(): void {  
    this.isLoading = true;
    const puid = this.activatedRoute.snapshot.paramMap.get('puid')
    if (puid) {
      this.puid = puid;
      this.fetchPartnerLogo(puid);
    }

    // Add scroll restoration after data is loaded
    Promise.resolve().then(() => {
      const savedPosition = sessionStorage.getItem('lastScrollPosition');
      if (savedPosition) {
        setTimeout(() => {
          window.scrollTo({
            top: parseInt(savedPosition, 10),
            behavior: 'auto'
          });
          sessionStorage.removeItem('lastScrollPosition');
        }, 100); 
      }
    });

    const savedFilter = localStorage.getItem('currentFilter');
    const savedSortOption = localStorage.getItem('sortOptionLabel');
    const savedScholarshipFilter = localStorage.getItem('isScholarshipFilterEnabled');
    const savedApplicationFeeWaiverFilter = localStorage.getItem('isApplicationFeeWaiverEnabled');
    const savedEnglishTestWaiverFilter = localStorage.getItem('isEnglishTestWaiverEnabled');
    const savedLoanOfferFilter = localStorage.getItem('isLoanOfferEnabled')

    // Check if cached results and filters exist
    const cachedResults = this.dataCacheService.getCachedData('results');
    const appliedFilters = localStorage.getItem('appliedFilters');
    const savedInitialFilter = localStorage.getItem('initialFilter');
    
    if (cachedResults && appliedFilters && savedInitialFilter) {
      console.log('Loading data from local storage cache');
      this.initialFilter = JSON.parse(savedInitialFilter);
      this.config = cachedResults;
      this.filteredConfig = [...this.config];
      this.currentFilter  = JSON.parse(appliedFilters); 

      this.sortOptionLabel = savedSortOption || 'Tuition Fees: High to Low';
      this.isScholarshipFilterEnabled = JSON.parse(savedScholarshipFilter || 'false');
      this.isApplicationFeeWaiverEnabled = JSON.parse(savedApplicationFeeWaiverFilter || 'false');
      this.isEnglishTestWaiverEnabled = JSON.parse(savedEnglishTestWaiverFilter || 'false');
      this.isLoanOfferEnabled = JSON.parse(savedLoanOfferFilter || 'false');

      this.applyLocalFilters();
      this.restoreScrollPosition();
      this.updateWishlistLength(); 
      setTimeout(()=>{
        this.markCoursesInWishlist();
      }, 1500);  
      this.isLoading = false;
    }  else {
      setTimeout(() => {
        this.loadStudentDetailsAndFetchResults();
        
      }, 500); 
    }
  } 

  @HostListener('window:beforeunload')
  saveScrollPosition() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    sessionStorage.setItem('lastScrollPosition', scrollPosition.toString());
  }

  // Method to filter courses based on selected filters
  applyLocalFilters(): void {
  this.filteredConfig = this.config.filter(course => {

    let matchesScholarship = true;
    let matchesFeeWaiver = true;
    let matchesEnglishTestWaiver = true;
    let matchesLoanOffers = true;

    // Apply scholarship filter if enabled
    if (this.isScholarshipFilterEnabled) {
      matchesScholarship = course.scholarship_available === 'Yes';
    }

    // Apply application fee waiver filter if enabled
    if (this.isApplicationFeeWaiverEnabled) {
      matchesFeeWaiver = course.application_fee === 'Waiver' || course.application_fee === '0';
    }

    if (this.isEnglishTestWaiverEnabled) {
      matchesEnglishTestWaiver = course.english_proficiency_required === 'Yes';
    }

    if (this.isLoanOfferEnabled) {
      matchesLoanOffers = course.loan_offers === 'Yes';
    }

    return matchesScholarship && matchesFeeWaiver && matchesEnglishTestWaiver && matchesLoanOffers;
  });
  console.log("Filtered Courses:", this.filteredConfig);
  }

  
  toggleSortDropdown() {
    this.isSortDropdownOpen = !this.isSortDropdownOpen;
  }

  // Sort courses by tuition fees
  sortCourses(order: 'high-to-low' | 'low-to-high' | 'duration-low-to-high' | 'duration-high-to-low') {
    if (order === 'high-to-low') {
      this.filteredConfig.sort((a, b) => b.yearly_tuition_fees - a.yearly_tuition_fees);
      this.sortOptionLabel = 'Tuition Fees: High to Low';
    } else if (order === 'low-to-high') {
      this.filteredConfig.sort((a, b) => a.yearly_tuition_fees - b.yearly_tuition_fees);
      this.sortOptionLabel = 'Tuition Fees: Low to High';
    } else if (order === 'duration-low-to-high') {
      this.filteredConfig.sort((a, b) => parseDuration(a.duration) - parseDuration(b.duration));
      this.sortOptionLabel = 'Duration: Low to High';
    } else if (order === 'duration-high-to-low') {
      this.filteredConfig.sort((a, b) => parseDuration(b.duration) - parseDuration(a.duration));
      this.sortOptionLabel = 'Duration: High to Low';
    }
    this.isSortDropdownOpen = false; 
  }

  filteredSpecializations(): string[] {
    return this.availableSpecializations.filter(specialization =>
      specialization.toLowerCase().includes(this.specializationSearchTerm.toLowerCase())
    );
  }
  

  toggleSpecializationDropdown() {
    this.isSpecializationDropdownOpen = !this.isSpecializationDropdownOpen;
  }

  // Method to handle specialization selection
  selectSpecialization(specialization: string) {
    this.selectedSpecialization = specialization; // Set the selected specialization
    this.currentFilter.course = specialization; 
    this.specializationSearchTerm = ''; // Reset the search term after selection
    this.isSpecializationDropdownOpen = false; // Close the dropdown
  }
  

    // Add this method in your TypeScript file
  getCountryFlag(countryName: string): string | undefined {
    const country = this.countryOptions.find(c => c.name === countryName);
    return country ? country.flagUrl : undefined;
  }

  fetchPartnerLogo(puid: string): void {
    this.partnerService.getPartnerLogo(puid).subscribe(
      (response) => {
        this.partnerLogo = response.data.company.logo; 
      },
      (error) => {
        console.error('Error fetching partner logo:', error);
      }
    );
  }


  restoreScrollPosition(): void {
    const savedPosition = localStorage.getItem('scrollPosition');
    if (savedPosition) {
      window.scrollTo(0, parseInt(savedPosition)); 
      localStorage.removeItem('scrollPosition');  
    }
  }

  // Load student details and fetch results based on target country, degree, and course
  loadStudentDetailsAndFetchResults(): void {
    const uid = localStorage.getItem('uid'); 

    if (!uid) {
      console.error('UID not found in local storage');
      return;
    }

    this.http.get(`${this.baseUrl}/student-choices/get-student-details/${uid}`).subscribe(
      (response: any) => {
        const studentDetails = response.data;
        console.log('Student Details:', studentDetails);

        // Determine student_percentage and student_backlog based on the type of academicHistory data
        let studentPercentage = null;
        let studentBacklog = 0; 

        if (studentDetails.academicHistory?.undergraduateMarks?.percentage) {
          studentPercentage = studentDetails.academicHistory.undergraduateMarks.percentage;
          studentBacklog = studentDetails.academicHistory.undergraduateMarks.degreeBacklogs;
        } else if (studentDetails.academicHistory?.highSchoolMarks?.percentage) {
          studentPercentage = studentDetails.academicHistory.highSchoolMarks.percentage;
          studentBacklog = 0;
        }

        // Create initialFilter with extracted and additional data
        this.initialFilter = {
          country: studentDetails.targetCountry,
          studyLevel: studentDetails.targetDegree,
          course: studentDetails.targetCourse,
          firstName: studentDetails.firstName,
          student_percentage: studentPercentage, // Add student percentage
          student_backlog: studentBacklog       // Add student backlog
        };
        
        localStorage.setItem('initialFilter', JSON.stringify(this.initialFilter));
        this.currentFilter = { ...this.initialFilter };
        localStorage.setItem('appliedFilters', JSON.stringify(this.currentFilter));
        this.applyFiltersForResult(this.currentFilter);
      },
      (error) => {
        console.error('Error fetching student details:', error);
      }
    );
  }

  // Apply the filters and fetch results from backend
  applyFiltersForResult(filterData: any): void {
    console.log('Filter Data Applied:', filterData);
    this.isLoading = true;
    this.http.post(`${this.baseUrl}/university-courses/receive-data`, filterData).subscribe(
      (response: any) => {
        console.log('Filtered Data:', response.data);
        this.config = response.data;
        this.filteredConfig = [...this.config];
        this.dataCacheService.setCachedData('results', response.data);
        this.updateWishlistLength();

        this.isLoading = false;
      },
      (error) => {
        console.error('Error applying filters:', error);
        this.isLoading = false;
      }
    );
  }

  
  updateResults(newConfig: any[]) {
    this.config = newConfig;
    this.totalUniversities = this.config.length;
    // this.currentPage = 1;
  }

  viewDetails(course: any) {
    const puid = this.activatedRoute.snapshot.paramMap.get('puid');
    console.log(puid,49)
    console.log(course.course_id,50)
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    sessionStorage.setItem('lastScrollPosition', scrollPosition.toString());

    // Save the current filter state to local storage
    localStorage.setItem('currentFilter', JSON.stringify(this.currentFilter));
    localStorage.setItem('sortOptionLabel', this.sortOptionLabel);
    localStorage.setItem('isScholarshipFilterEnabled', JSON.stringify(this.isScholarshipFilterEnabled));
    localStorage.setItem('isApplicationFeeWaiverEnabled', JSON.stringify(this.isApplicationFeeWaiverEnabled));
    localStorage.setItem('isEnglishTestWaiverEnabled', JSON.stringify(this.isEnglishTestWaiverEnabled));
    localStorage.setItem('isLoanOfferEnabled', JSON.stringify(this.isLoanOfferEnabled));

    // const navigationExtras = {
    //   queryParams: { course_id: course.course_id },
    //   state: { origin: 'results' } // Specify the origin as 'results'
    // };

    if (puid) {
      this.router.navigate(['/university-detail', course.course_id, puid]);
    } else {
      this.router.navigate(['/university-detail', course.course_id]); 
    }
  }

  addToWishlist(course: any) {
    if (this.wishlistLength >= 30) {
      console.error('You cannot shortlist more than 30 courses.');
      alert('You cannot shortlist more than 30 courses.');
      return;
    }
    this.wishlistService.addToWishlist(course).subscribe(
      (response: any) => {
        console.log('Course added to wishlist:', response);
        course.isInWishlist = true; 
        this.updateWishlistLength();
      },
      (error) => {
        console.error('Error adding course to wishlist:', error);
      }
    );
  }


  viewWishlist() {

    const puid = this.activatedRoute.snapshot.paramMap.get('puid');
    console.log(puid,76)

    if(puid){
      this.router.navigate(['/wishlist/'+puid]);
    }
    else{
      this.router.navigate(['/wishlist']);
    }
  }

  toggleTooltip(index: number, show: boolean) {
    this.showTooltip[index] = show;
  }


  // Function to update wishlist length
  updateWishlistLength() {
    const token = localStorage.getItem('access_token');
    const uid = localStorage.getItem('uid');

    if (!uid) {
      console.error('No UID found in local storage');
      throw new Error('No UID found in local storage');
    }

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'uid': uid 
    });

    this.wishlistService.getWishlist(headers).subscribe(
      (response: any) => {
        if (response && response.data) {
          this.wishlistLength = response.data.length; 
          console.log('Wishlist Length:', this.wishlistLength);
          this.isLoading = false;
        } else {
          this.wishlistLength = 0; 
          this.isLoading = false;
        }
      },
      (error) => {
        console.error('Error fetching wishlist:', error);
        this.wishlistLength = 0; 
        this.isLoading = false;
      }
    );
  }
  


  viewProfile() {

    const puid = this.activatedRoute.snapshot.paramMap.get('puid');
    console.log(puid,123)

    if(puid){
      this.router.navigate(['/personal-details/'+puid]);
    }
    else{
      this.router.navigate(['/personal-details']);
    }
  }

  // Pagination methods
  // public get totalPages(): number {
  //   return Math.ceil(this.config.length / this.itemsPerPage);
  // }

  // public nextPage(): void {
  //   if (this.currentPage < this.totalPages) {
  //     this.currentPage++;
  //   }
  // }

  // public previousPage(): void {
  //   if (this.currentPage > 1) {
  //     this.currentPage--;
  //   }
  // }

  // public goToPage(pageNumber: number): void {
  //   if (pageNumber >= 1 && pageNumber <= this.totalPages) {
  //     this.currentPage = pageNumber;
  //   }
  // }

  // Toggle filter drawer
  toggleFilterDrawer(): void {
    this.isFilterDrawerOpen = !this.isFilterDrawerOpen;

  }

  // Apply advance filters logic
  applyFilters(): void {

    if (this.currentFilter.studyLevel === 'Bachelors') {
      this.currentFilter.studyLevel = 'Undergraduate'; 
    } else if (this.currentFilter.studyLevel === 'Masters') {
      this.currentFilter.studyLevel = 'Postgraduate';  
    }

    console.log('Applying filters:', this.currentFilter);

    this.applyFiltersToData(this.currentFilter);
    this.isFilterDrawerOpen = false;
  }

  applyFiltersToData(filterData: any): void {
    console.log('Filter Data Applied:', filterData);
    localStorage.setItem('appliedFilters', JSON.stringify(filterData));
    this.isLoading = true;
    this.http.post(`${this.baseUrl}/university-courses/apply-filters`, filterData).subscribe(
      (response: any) => {
        console.log('Filtered Data:', response.data);
        this.config = response.data;
        this.filteredConfig = [...this.config];
        this.dataCacheService.setCachedData('results', response.data);
        this.filterApplied.emit(this.config);
        this.isLoading = false;
      },
      (error) => {
        console.error('Error applying filters:', error);
        this.isLoading = false;
      }
    );
  }


  // Function to update wishlist and wishlist length
  updateWishlist() {
    const token = localStorage.getItem('access_token');
    const uid = localStorage.getItem('uid');

    if (!uid) {
      console.error('No UID found in local storage');
      throw new Error('No UID found in local storage');
    }

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'uid': uid 
    });

    this.wishlistService.getWishlist(headers).subscribe(
      (response: any) => {
        if (response && response.data) {
          this.wishlist = response.data; 
          this.wishlistLength = this.wishlist.length; 
          this.config.forEach(course => {
            course.isInWishlist = this.wishlist.some(wish => wish.courseId === course.course_id);
          });

          console.log('Wishlist Length:', this.wishlistLength);
          this.isLoading = false;
        } else {
          this.wishlistLength = 0; 
          this.isLoading = false;
        }
      },
      (error) => {
        console.error('Error fetching wishlist:', error);
        this.wishlistLength = 0; 
        this.isLoading = false;
      }
    );
  }

   // Fetch wishlist and mark courses already in it
  markCoursesInWishlist() {
    const token = localStorage.getItem('access_token');
    const uid = localStorage.getItem('uid');

    if (!uid) {
      console.error('No UID found in local storage');
      throw new Error('No UID found in local storage');
    }

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'uid': uid
    });

    this.wishlistService.getWishlist(headers).subscribe(
      (response: any) => {
        const wishlist = response.data; 

        console.log('Wishlist:', wishlist);
        console.log('Config:', this.config);

        this.config.forEach(course => {
          course.isInWishlist = wishlist.some((wish: any) => String(wish.courseId) === String(course.course_id));
        });
        this.isWishlistProcessed = true;
      },
      (error) => {
        console.error('Error fetching wishlist:', error);
        this.isWishlistProcessed = true;
      }
    );
  }

    // Open the Connect to Counsellor modal
    openConnectModal() {
      this.connectCounsellor.openModal();
    }
  
    // Handle successful request submission
    onRequestSubmitted() {
      console.log('Counsellor request submitted successfully!');
    }


}
