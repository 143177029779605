import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core'; 
import { SharedService } from '../services/shared.service';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input-gg';
import { environment } from '../../environments/environment';

interface PhoneNumber {
  e164Number: string;
  internationalNumber: string;
  nationalNumber: string;
  number: string;
  countryCode: string;
  dialCode: string;
}


@Component({
  selector: 'app-user-info-page',
  templateUrl: './user-info-page.component.html',
  styleUrls: ['./user-info-page.component.scss']
})
export class UserInfoPageComponent implements OnInit {
  private baseUrl: string = environment.apiBaseUrl;
  model: any = {};
  form = new FormGroup({});
  finalPayload: any = { entryTests: []}; 
  step = 'studyDetails'; 
  progressBarWidth: string = '0%';
  otp: string = '';
  otpArray = Array(6); 
  otpValues: string[] = ['', '', '', '', '', '']; 
  countdown: number = 59; 
  countdownInterval: any;
  public searchCountryField = SearchCountryField;
  public countryISO = CountryISO;
  public phoneNumberFormat = PhoneNumberFormat;
  showError: boolean = false;
  userDetailsField: FormlyFieldConfig[] = []; 


  countryOptions: { name: string, flagUrl: string }[] = [
    { name: 'US', flagUrl: 'https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1729754442825United%20States%20of%20America%201.png' },
    { name: 'UK', flagUrl: 'https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1729754474318UK-flag.png' },
    { name: 'Canada', flagUrl: 'https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1729754513112Canada-flag.png' },
    { name: 'Germany', flagUrl: 'https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1729754544994germany-flag.png' },
    { name: 'Australia', flagUrl: 'https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1729754573315Australia%201.png' }
  ];
  
  
  
  degreeOptions: string[] = ['Bachelors', 'Masters'];
  listOfCourses: string[] = ["Accounting", "Applied Mathematical Economics", "Bioengineering", "Biosystems Engineering", "Business Administration", "Business Data Analytics", "Business Economics", "Chemical Engineering", "Chemistry", "Civil Engineering",
     "Computer Analysis", "Computer Science", "Computer Systems", "Cyber Security", "Data Science", "Economics", "Electrical Engineering", "Electrical and Computer Engineering", "Energy Science and Technology", "Engineering Management", "Engineering Physics", 
     "Fashion", "Finance", "Human Resources Management", "Information Technology", "Integrated Industrial Design", "International Economic Law", "International Trade", "Investment & Finance", 
     "Mathematical Finance", "Mathematics", "Mechanical Engineering", "National Security Studies", "Physics", "Plastics Engineering Technology", "Polymer and Fiber Engineering", "Psychology", "Public Relations", "Quantitative Finance", 
     "Robotics Engineering", "Sports Marketing"];

  isCourseDropdownOpen: boolean = false;
  courseSearchTerm: string = '';

  courseOptions: string[] = [
    'Computer Science', 'Data Science', 'Information Technology', 'Cybersecurity',
    'Finance','Mechanical Engineering', 'Electrical Engineering',
   'Finance', 'Economics', 'MBA', 'Artificial Intelligence', 'Software Engineering',
  ];
  aptitudeTestOptions: string[] = ['GRE', 'GMAT', 'Not Taken Yet'];
  languageTestOptions: string[] = ['IELTS', 'TOEFL', 'PTE', 'DET', 'Not Taken Yet'];
  professionOptions: string[] = ['Student', 'Working'];

  readonly maxScores = {
    IELTS: 9.0,
    TOEFL: 120,
    PTE: 90,
    DET: 160,
    GRE: 340,
    GMAT: 800,
  };


  constructor(private sharedService: SharedService,
    private router: Router,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute) 
  {}

  ngOnInit(): void {
    this.setUserDetailsLayout(); 
    this.updateProgressBar();
  }
  

  // Toggle dropdown visibility
  toggleCourseDropdown() {
    this.isCourseDropdownOpen = !this.isCourseDropdownOpen;
  }

  // Filter courses based on search term
  filteredCourses(): string[] {
    return this.listOfCourses.filter(course =>
      course.toLowerCase().includes(this.courseSearchTerm.toLowerCase())
    );
  }

  // Select a course from the dropdown
  selectCourse(course: string) {
    this.finalPayload.targetCourse = course; // Update final payload with selected course
    this.courseSearchTerm = '';              // Reset search term after selection
    this.isCourseDropdownOpen = false;       // Close the dropdown
    console.log('Selected course:', this.finalPayload.targetCourse); // Debugging log
  }

  moveFocus(index: number) {
    const nextInput = document.querySelectorAll('.otp-box')[index + 1] as HTMLInputElement;
    if (this.otpValues[index] && nextInput) {
      nextInput.focus(); 
    }
  }

  resendOtp() {
    this.countdown = 59;
    this.startCountdown(); 
    this.sendOtpToUser();
  }

  // Function to dynamically generate placeholders based on selected test
  generatePlaceholder(testName: 'IELTS' | 'TOEFL' | 'PTE' | 'DET' | 'GRE' | 'GMAT'): string {
    if (this.maxScores[testName] !== undefined) {
      return `Enter a score between 0 and ${this.maxScores[testName]}`;
    }
    return 'Enter your score';
  }


  editMobileNumber() {
    this.step = 'registration';
    clearInterval(this.countdownInterval);
  }

  startCountdown() {
    this.countdown = 59; 
    clearInterval(this.countdownInterval);
    this.countdownInterval = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        clearInterval(this.countdownInterval);
      }
    }, 1000);
  }


  concatenateOTP() {
    const otp = this.otpValues.join('');
    this.finalPayload.otp = otp;
    console.log('Concatenated OTP:', otp);  
  }

  // Select methods for button clicks
  selectCountry(country: { name: string, flagUrl: string }) {
    this.finalPayload.targetCountry = country.name;
  }

  selectDegree(degree: string) {
    if (degree === 'Bachelors') {
        this.finalPayload.targetDegree = 'Undergraduate';  
    } else if (degree === 'Masters') {
        this.finalPayload.targetDegree = 'Postgraduate';  
    }
  }

  // selectCourse(course: string) {
  //   this.finalPayload.targetCourse = course;
  //   console.log('Selected course:', this.finalPayload.targetCourse);
  // }

  selectAptitudeTest(test: string) {
    this.finalPayload.aptitudeTest = test;

    if (test === 'Not Taken Yet') {
      this.finalPayload.aptitudeTestScore = null;  
      this.addEntryTest(test, null);  
    } else {
      this.addEntryTest(test, this.finalPayload.aptitudeTestScore);
    }
  }
  updateAptitudeTestScore(score: number) {
    this.finalPayload.aptitudeTestScore = score;
    if (this.finalPayload.aptitudeTest !== 'Not Taken Yet') {
      this.addEntryTest(this.finalPayload.aptitudeTest, score);
    }
  }

  selectLanguageTest(test: string) {
    this.finalPayload.languageTest = test;

    if (test === 'Not Taken Yet') {
      this.finalPayload.languageTestScore = null;  
      this.addEntryTest(test, null);  
    } else {
      this.addEntryTest(test, this.finalPayload.languageTestScore);
    }
  }
  updateLanguageTestScore(score: number) {
    this.finalPayload.languageTestScore = score;
    if (this.finalPayload.languageTest !== 'Not Taken Yet') {
        this.addEntryTest(this.finalPayload.languageTest, score);
    }
  }

  addEntryTest(testName: string, testScore: number | null) {
    this.finalPayload.entryTests = this.finalPayload.entryTests || [];

    this.finalPayload.entryTests.push({
        testName: testName,
        testScore: testScore
    });
 }

 updateTestScore(testName: string, testScore: number | null) {
  const testEntry = this.finalPayload.entryTests.find((t: any) => t.testName === testName);
  if (testEntry) {
      testEntry.testScore = testScore;
  }
}

  onSubmit() {

    // Check if required fields are filled
    if (this.step === 'studyDetails') {
      if (!this.finalPayload.targetCountry || !this.finalPayload.targetDegree || !this.finalPayload.targetCourse) {
        this.showError = true;
        console.error("Please make sure all required fields are filled.");
        return;
      }
    }
    else if (this.step === 'testScores') {
      if (!this.finalPayload.aptitudeTest || (this.finalPayload.aptitudeTest !== 'Not Taken Yet' && !this.finalPayload.aptitudeTestScore)) {
        this.showError = true;
        console.error("Aptitude test or score is missing.");
        return; 
      }

      if (!this.finalPayload.languageTest || (this.finalPayload.languageTest !== 'Not Taken Yet' && !this.finalPayload.languageTestScore)) {
        this.showError = true;
        return;
      }
      }
      else if (!this.form.valid) {
        this.showError = true; // Display error messages for Formly form
        console.error("Please fill all required fields in the form.");
        return; // Prevent moving to the next step
      }
      this.showError = false; 

    if (this.step === 'registration') {
      this.sendOtpToUser(); 
    } else if (this.step === 'otpVerification') {
      this.verifyOtp(); 
    } else {
      this.handleSteps();
    }
  }

  // Submit form logic for each step
  handleSteps() {
    this.storeData(this.form.value);

    // Revalidate the form after setting up the layout
    this.form.markAsUntouched();
    this.form.markAsPristine();
    this.form.updateValueAndValidity();

    console.log('Form Validity after navigation:', this.form.valid);
    console.log('Current Step:', this.step);
    console.log('Final Payload:', this.finalPayload);

    if (!this.finalPayload.targetCountry || !this.finalPayload.targetDegree || !this.finalPayload.targetCourse) {
      console.error("Please make sure all required fields are filled.");
      return;
    }
    if (this.finalPayload.aptitudeTest) {
      this.updateTestScore(this.finalPayload.aptitudeTest, this.finalPayload.aptitudeTestScore);
    }
    if (this.finalPayload.languageTest) {
      this.updateTestScore(this.finalPayload.languageTest, this.finalPayload.languageTestScore);
    }
  
    // Step 1: Study Plans
    if (this.step === 'studyDetails') {
      if (this.finalPayload.targetDegree === 'Postgraduate') {
        this.step = 'undergraduateDetails';
      } else if (this.finalPayload.targetDegree === 'Undergraduate') {
        this.step = 'highSchoolDetails';
      } else {
        this.step = 'testScores';
      }
    }
  
    // Step 2: Undergraduate/High School Details
    else if (this.step === 'undergraduateDetails' || this.step === 'highSchoolDetails') {
      this.step = 'testScores';
    }
  
    // Step 3: Test Scores
    else if (this.step === 'testScores') {
      this.step = 'professionDetails';
    }
  
    // Step 4: Profession
    else if (this.step === 'professionDetails') {
      this.step = 'registration';
    }
    else if (this.step === 'registration') {
      this.step = 'otpVerification';
    }
    // Update the form layout and progress bar for the new step
    this.setUserDetailsLayout();
    this.updateProgressBar();
  }
  

  // Handle the previous step
  prevStep() {
    if (this.step === 'otpVerification') {
      this.step = 'registration';      
    }else if (this.step === 'registration') {
      this.step = 'professionDetails';
    }else if (this.step === 'professionDetails') {
      this.step = 'testScores';
    } else if (this.step === 'testScores') {
      if (this.finalPayload.targetDegree === 'Masters') {
        this.step = 'undergraduateDetails';
      } else if (this.finalPayload.targetDegree === 'Bachelors') {
        this.step = 'highSchoolDetails';
      } else {
        this.step = 'studyDetails';
      }
    } else if (this.step === 'undergraduateDetails' || this.step === 'highSchoolDetails') {
      this.step = 'studyDetails';
    }

    this.setUserDetailsLayout();
    this.updateProgressBar();
  }

  // Store data at each step
  storeData(result: any) {
    this.finalPayload = this.finalPayload || {};

    if (this.step === 'studyDetails') {
      // Make sure button-based selections are stored in finalPayload
      if (!this.finalPayload.targetCountry || !this.finalPayload.targetDegree) {
        console.error('Missing country or degree selection');
        return;
      }
      // Store form-based inputs (like targetCourse)
      // this.finalPayload.targetCourse = result.targetCourse;
    } else if (this.step === 'undergraduateDetails') {
      this.finalPayload.undergraduateCollegeName = result.undergraduateCollegeName;
      this.finalPayload.courseTaken = result.courseTaken;
      this.finalPayload.undergraduateScore = result.undergraduateScore;
      this.finalPayload.backlogs = result.backlogs;
      // if (result.undergraduateScore) {
      //   localStorage.setItem('student_percentage', result.undergraduateScore);
      // }
      // if (result.backlogs) {
      //   localStorage.setItem('student_backlogs', result.backlogs);
      // }

    } else if (this.step === 'highSchoolDetails') {
      this.finalPayload.highSchoolName = result.highSchoolName;
      this.finalPayload.highSchoolScore = result.highSchoolScore;
      // if (result.highSchoolScore) {
      //   localStorage.setItem('student_percentage', result.highSchoolScore);
      // }
      // if (result.highSchoolScore) {
      //   localStorage.setItem('student_backlogs', '0' );
      // }

    } else if (this.step === 'testScores') {
      // this.addEntryTest(this.finalPayload.aptitudeTest, result.aptitudeTestScore);
      // this.addEntryTest(this.finalPayload.languageTest, result.languageTestScore);
    } else if (this.step === 'professionDetails') {
      this.finalPayload.profession = result.profession;
      this.finalPayload.months_of_experience = result.profession === 'Working' ? result.months_of_experience : null;
    }
    console.log('Updated finalPayload:', this.finalPayload);
  }
  

  // Submit the final payload
  submitFinalPayload() {
    if (!this.form.valid) {
      console.error('Form is not valid');
      return;
    }

    const puid = this.activatedRoute.snapshot.paramMap.get('puid');
    if (puid) {
      this.finalPayload['puid'] = puid;
    }

    const uid = localStorage.getItem('uid');
    if (uid) {
      this.finalPayload['uid'] = uid;
    }

    const created = localStorage.getItem('created');
    if (created) {
      this.finalPayload['created'] = created;
    }

    const token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

  
    // Safely check if form control for phone number exists
    const phoneControl = this.form.get('phoneNumber');
    if (phoneControl && phoneControl.value) {
      const phoneControlValue = phoneControl.value as PhoneNumber;
      this.finalPayload.phoneNumber = phoneControlValue.e164Number;
    } else {
      console.error('Phone number is missing or invalid');
      return;  
    }

    console.log(this.finalPayload,186)
    this.http.post(`${this.baseUrl}/student-choices/save-student-choices`, this.finalPayload, { headers }).subscribe(
      (response: any) => {
        console.log('Data saved successfully', response);
        if (response && response.success && response.userDetails && response.userDetails.uid) {
          this.sharedService.setUid(response.userDetails.uid);

        if (puid) {
          this.router.navigate(['/results/' + puid], { state: { data: response.data } });
        } else {
          this.router.navigate(['/results'], { state: { data: response.data } });
        }
      }
      },
      (error: any) => {
        console.error('Error saving data', error);
      }
    );

  }

  // Set the form fields using Formly for dropdown-based inputs
  setUserDetailsLayout() {
    if (this.step === 'undergraduateDetails') {
      this.userDetailsField = [
        {
          key: 'undergraduateCollegeName',
          type: 'input',
          className: 'form-undergraduate-college', 
          templateOptions: {
            label: 'Undergraduation college name?',
            placeholder: 'Select college',
            required: true
          },
          validation: {
            messages: {
              required: 'Please enter your undergraduation college name.',
            },
          },
        },
        {
          key: 'courseTaken',
          type: 'input',
          className: 'form-course-taken', 
          templateOptions: {
            label: 'What course did you take?',
            placeholder: 'e.g: Electronics & Communication Engineering',
            required: true
          },
          validation: {
            messages: {
              required: 'Please enter your course.',
            },
          },
        },
        {
          fieldGroupClassName: 'flex flex-row space-x-4 custom-input',  
          fieldGroup: [
            {
              key: 'undergraduateScore',
              type: 'input',
              className: 'form-score-percentage', 
              templateOptions: {
                label: 'Your score/expected score?',
                placeholder: 'e.g: 80%',
                required: true
              },
              validation: {
                messages: {
                  required: 'Please enter your score.',
                },
              },
            },
            {
              key: 'backlogs',
              type: 'input',
              className: 'form-backlog-count', 
              templateOptions: {
                label: 'Do you have any backlogs?',
                placeholder: 'e.g: 0',
                type: 'number',
                min: 0,
                required: true
              },
              validation: {
                messages: {
                  required: 'Please enter the number of backlogs.',
                },
              },
            }
          ]
        },
        {
          type: 'template',
          template: `
            <div class="info-text text-blue-500">
              <p> If your marks are in CGPA, please convert it to</p>
               <p>percentage before entering - <a href="https://cgpaintopercentage.com/gpa-to-percentage/" target="_blank" class="text-blue-500 underline">Use this tool</a></p>
            </div>`
        }
      ];
    }
    
    else if (this.step === 'highSchoolDetails') {
          this.userDetailsField = [
            {
              key: 'highSchoolName',
              type: 'input',
              templateOptions: {
                label: 'High School Board',
                placeholder: 'Enter your high school board',
                required: true
              },
              validation: {
                messages: {
                  required: 'Please enter your high school name.',
                },
              },
            },
            {
              key: 'highSchoolScore',
              type: 'input',
              templateOptions: {
                label: 'Score (Percentage)',
                placeholder: 'e.g: 80%',
                required: true
              },
              validation: {
                messages: {
                  required: 'Please enter your score between 0 and 100.',
                },
              },
            }
          ];
    }else if (this.step === 'professionDetails') {
      this.userDetailsField = [
        {
          key: 'profession',
          type: 'select',
          templateOptions: {
            label: 'Profession',
            placeholder: 'Are you a student or working?',
            options: [
              { label: 'Student', value: 'Student' },
              { label: 'Working', value: 'Working' }
            ],
            required: true
          },
          validation: {
            messages: {
              required: 'Please select your profession.',
            },
          },
        },
        {
          key: 'months_of_experience',
          type: 'input',
          hideExpression: (model) => model.profession !== 'Working', 
          templateOptions: {
            label: 'Months of Experience',
            placeholder: 'Enter your experience in months',
            type: 'number',
            min: 0,
            required: true
          },
          validation: {
            messages: {
              required: 'Please enter your months of experience.',
            },
          },
        }
      ];
    }
    else if (this.step === 'registration')  {
      this.userDetailsField = [
        {
          fieldGroupClassName: 'flex space-x-4 mb-4',
          fieldGroup: [
            {
              key: 'firstName',
              type: 'input',
              className: 'w-full md:w-1/2',
              templateOptions: {
                label: 'First Name',
                placeholder: 'Enter your first name',
                required: true,
              },
              validation: {
                messages: {
                  required: 'First name is required.',
                },
              },
            },
            {
              key: 'lastName',
              type: 'input',
              className: 'w-full md:w-1/2',
              templateOptions: {
                label: 'Last Name',
                placeholder: 'Enter your last name',
                required: true,
              },
              validation: {
                messages: {
                  required: 'Last name is required.',
                },
              },
            },
          ],
        },
        {
          key: 'email',
          type: 'input',
          className: 'w-full mb-4',
          templateOptions: {
            label: 'Email Address',
            placeholder: 'Enter your email',
            type: 'email',
            required: true,
          },
          validation: {
            messages: {
              required: 'Email address is required.',
            },
          },
        },
        {
          fieldGroupClassName: 'flex flex-row space-x-4 mt-4 custom-input',  
          fieldGroup: [
          {
          key: 'phoneNumber',
          type: 'intl-tel-input',
          wrappers: ['form-field'],
          className: 'w-full mb-4 custom-phone-field',
          templateOptions: {
            label: 'Mobile Number',
            placeholder: 'Enter your phone number',
            required: true,
            preferredCountries: [this.countryISO.India, this.countryISO.UnitedStates],
            enableAutoCountrySelect: true,
            enablePlaceholder: true,
            searchCountryFlag: true,
            searchCountryField: [this.searchCountryField.Iso2, this.searchCountryField.Name],
            selectFirstCountry: true,
            phoneValidation: true,
            separateDialCode: true,
            numberFormat: this.phoneNumberFormat.International,
          },
          validation: {
            messages: {
              required: 'Please enter a valid phone number.',
            },
          },
          }
         ]
        },
      ];
    }
    else if (this.step === 'otpVerification')  {
      this.userDetailsField = [
        {
          key: 'otp',
          type: 'input',
          templateOptions: {
            label: 'Enter the 6-digit OTP sent to your phone',
            placeholder: 'Enter OTP',
            maxLength: 6,
            required: true,
          },
        },
      ];
    }
    this.form.updateValueAndValidity();
  }

  // Update progress bar width based on the step
  updateProgressBar() {
    switch (this.step) {
      case 'studyDetails':
        this.progressBarWidth = '20%';
        break;
      case 'undergraduateDetails':
      case 'highSchoolDetails':
        this.progressBarWidth = '40%';
        break;
      case 'testScores':
        this.progressBarWidth = '60%';
        break;
      case 'professionDetails':
        this.progressBarWidth = '80%';
        break;
      case 'registration':
        this.progressBarWidth = '100%';
        break;
      default:
        this.progressBarWidth = '0%';
    }    
  }
   // Function to send OTP to the user's mobile number
   sendOtpToUser() {
    const userType = 'student';
    const puid = this.activatedRoute.snapshot.paramMap.get('puid');
    const mobileNumber = this.finalPayload.phoneNumber?.e164Number;
    if (!mobileNumber) {
      console.error('Invalid phone number');
      return;
    }

    // this.http.post('http://127.0.0.1:3000/api/users', {
    this.http.post('https://dev.foreignadmits.app/api/users', {
      mobile: mobileNumber,
      type: userType,
      usersAvailOtp: true,
      puid: puid
    })
    .subscribe(
      (response: any) => {
        console.log('Mobile number sent successfully for OTP', response);
        this.step = 'otpVerification'; 
        this.setUserDetailsLayout(); 
        this.updateProgressBar();
      },
      (error) => {
        console.error('Error sending mobile number', error);
      }
    );
    this.startCountdown();
  }

  // Function to verify OTP entered by the user
  verifyOtp() {

    const otp = this.otpValues.join('');

    if (!otp) {
      console.error('Invalid OTP');
      return;
    }
    const mobileNumber = this.finalPayload.phoneNumber?.e164Number;
    if (!mobileNumber) {
      console.error('Invalid phone number');
      return;
    }

    console.log(this.finalPayload.otp, 549)
    const puid = this.activatedRoute.snapshot.paramMap.get('puid');
    // this.http.post('http://127.0.0.1:3000/api/users/login-with-mobile-otp', {
    this.http.post('https://dev.foreignadmits.app/api/users/login-with-mobile-otp', {
      mobile: mobileNumber,
      otp: otp,
    })
    .subscribe(
      (response: any) => {
        console.log('OTP verified successfully', response);
        localStorage.setItem('access_token', response.id);
        localStorage.setItem('uid', response.userDetails.uid);
        localStorage.setItem('created', response.created);
        
        // After verification, save the user data
        this.submitFinalPayload();
        if (puid) {
          this.router.navigate(['/results/' + puid]);
        } else {
          this.router.navigate(['/results']);
        }
      },
      (error) => {
        console.error('OTP verification failed', error);
      }
    );
  }

}
