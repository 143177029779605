import { Component, OnInit } from '@angular/core';
import { WishlistService } from '../wishlist.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.scss']
})
export class WishlistComponent implements OnInit {
  wishlist: any[] = [];
  wishlistLength: number = 0;  

  constructor(private wishlistService: WishlistService, private router: Router) { }

  ngOnInit(): void {
    this.updateWishlistLength();  
  }

  updateWishlistLength(): void {
    this.wishlistService.getWishlist().subscribe(
      (wishlist: any[]) => {
        this.wishlist = wishlist;
        this.wishlistLength = wishlist.length;  
      },
      (error) => {
        console.error('Error fetching wishlist:', error);
      }
    );
  }

  viewWishlist(): void {
    this.router.navigate(['/wishlist']);
  }
}
