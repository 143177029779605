import { Component, OnInit,  HostListener,ElementRef  } from '@angular/core';
import { WishlistService } from '../wishlist.service';
import { HttpHeaders } from '@angular/common/http';
import { ActivatedRoute,Router } from '@angular/router';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
import * as XLSX from 'xlsx';
import { throwError } from 'rxjs';

interface TestScore {
  testName: string;
  minScore: number | null;
}


@Component({
  selector: 'app-wishlist-page',
  templateUrl: './wishlist-page.component.html',
  styleUrls: ['./wishlist-page.component.scss']
})
export class WishlistPageComponent implements OnInit {
  wishlist: any[] = [];
  isDownloadOptionsVisible = false;
  constructor(public wishlistService: WishlistService, private activatedRoute: ActivatedRoute, private router: Router, private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.loadWishlist();
  }

  loadWishlist() {
    const token = localStorage.getItem('access_token');
    const uid = localStorage.getItem('uid');

    if (!uid) {
      console.error('No UID found in local storage');
      throw new Error('No UID found in local storage');
    }
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'uid': uid  
    });

    this.wishlistService.getWishlist(headers).subscribe(
      (response: any) => { 
        console.log(response,37);
        this.wishlist = response.data;
      },
      (error) => {
        console.error('Error fetching wishlist:', error);
      }
    );
  }
  
  removeFromWishlist(course: any) {
    const courseId = course.courseId; 
    const token = localStorage.getItem('access_token');
    const uid = localStorage.getItem('uid');
  
    if (!uid) {
      console.error('No UID found in local storage');
      return;
    }
  
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'uid': uid
    });
  
    this.wishlistService.removeFromWishlist(courseId, headers).subscribe(
      (response) => {
        console.log('Course removed from wishlist:', response);
        this.wishlist = this.wishlist.filter(item => item.courseId !== courseId); 
      },
      (error) => {
        console.error('Error removing from wishlist:', error);
      }
    );
  }

  formatApplicationFee(course: any): string {
    const currencySymbol = course.country === 'US' ? '$' :
                           course.country === 'Germany' ? '€' :
                           course.country === 'Australia' ? 'AUD' :
                           course.country === 'UK' ? '£' : '';
  
    if (course.applicationFees === 'Waiver') {
      return 'Waiver';
    } else if (course.applicationFees !== null && course.applicationFees !== undefined && !isNaN(Number(course.applicationFees))) {
      return `${currencySymbol} ${course.applicationFees}`;
    } else {
      return 'N/A';
    }
  }

  prevStep(){
    const puid = this.activatedRoute.snapshot.paramMap.get('puid');
    console.log(puid,75)

    if(puid){
      this.router.navigate(['/results/'+puid]);
    }
    else{
      this.router.navigate(['/results']);
    }
  }
  
  downloadPDF() {
    const doc = new jsPDF({ orientation: 'landscape' });
    doc.setFontSize(18);
    doc.text('Your Shortlisted Universities', 14, 15);
  
    const rows = this.wishlist.map((course) => {
      const currencySymbol = course.country === 'US' ? '$' :
                             course.country === 'Germany' ? '€' :
                             course.country === 'Australia' ? 'AUD' :
                             course.country === 'UK' ? '£' : '';
  
      const applicationFeesDisplay = 
        course.applicationFees === "Waiver" ? "Waiver" : 
        course.applicationFees !== null && !isNaN(course.applicationFees) ? `${currencySymbol}${course.applicationFees}` : 
        'N/A';
  
      // Check if test_scores exists and is an array
      const testScores = Array.isArray(course.test_scores) && course.test_scores.length > 0
        ? course.test_scores
            .map((score: { test_name: string; min_score: number | null }) => 
              `${score.test_name}: ${score.min_score !== null ? score.min_score : 'N/A'}`)
            .join('\n')
        : 'N/A';
  
      return [
        course.universityName || 'N/A',
        course.courseName || 'N/A',
        course.country || 'N/A',
        `${currencySymbol}${course.tuitionFees || 'N/A'}/year`,
        course.courseDuration || 'N/A',
        course.intakeSeason || 'N/A',
        applicationFeesDisplay,
        testScores // Correctly formatted test scores
      ];
    });
  
    const headers = [['University', 'Course', 'Country', 'Tuition Fees', 'Duration', 'Intake Season', 'Application Fees', 'Test Scores Required']];
  
    (doc as any).autoTable({
      head: headers,
      body: rows,
      startY: 20, 
      theme: 'grid',
      headStyles: { fillColor: [41, 128, 185] },
      margin: { top: 10 },
      styles: { overflow: 'linebreak', cellWidth: 'wrap' },
      columnStyles: {
        0: { cellWidth: 50 }, 
        1: { cellWidth: 50 }, 
        2: { cellWidth: 20 }, 
        3: { cellWidth: 30 }, 
        4: { cellWidth: 25 }, 
        5: { cellWidth: 30 }, 
        6: { cellWidth: 20 }, 
        7: { cellWidth: 40 }  
      }
    });
  
    // Save the PDF
    doc.save('wishlist.pdf');
  }
  
  
  
  
  toggleDownloadOptions() {
    this.isDownloadOptionsVisible = !this.isDownloadOptionsVisible;
  }

    // Close dropdown if clicked outside
  @HostListener('document:click', ['$event'])
  handleOutsideClick(event: Event) {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.isDownloadOptionsVisible = false;
    }
  }


  downloadExcel() {
    const wishlistData = this.wishlist.map(item => {
      const { _id, courseId, tuitionFees, applicationFees, country, test_scores, ...rest } = item;
  
      const currencySymbol = country === 'US' ? '$' :
                             country === 'Germany' ? '€' :
                             country === 'Australia' ? 'AUD' :
                             country === 'UK' ? '£' : '';
  
      const formattedTuitionFees = tuitionFees ? `${currencySymbol} ${tuitionFees}` : 'N/A';
  
      const formattedApplicationFees = 
        applicationFees === 'Waiver' ? 'Waiver' :
        (applicationFees !== null && applicationFees !== undefined && !isNaN(Number(applicationFees))) ? `${currencySymbol} ${applicationFees}` : 'N/A';
  
      // Format test_scores as a single string if it exists and has valid entries
      const formattedTestScores = Array.isArray(test_scores) && test_scores.length > 0 
        ? test_scores
            .filter((score: any) => score.test_name && score.min_score !== undefined) 
            .map((score: any) => `${score.test_name}: ${score.min_score !== null ? score.min_score : 'N/A'}`)
            .join(', ')
        : 'N/A';
  
      return {
        courseName: rest.courseName,
        universityName: rest.universityName,
        courseDuration: rest.courseDuration,
        degree: rest.degree,
        intakeSeason: rest.intakeSeason,
        location: rest.location,
        country: country,
        tuitionFees: formattedTuitionFees,
        applicationFees: formattedApplicationFees,
        testScores: formattedTestScores 
      };
    });
  
    const worksheet = XLSX.utils.json_to_sheet(wishlistData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Wishlist');
    XLSX.writeFile(workbook, 'wishlist.xlsx');
  }


  viewDetails(course: any) {
    const puid = this.activatedRoute.snapshot.paramMap.get('puid');

    // const navigationExtras = {
    //   queryParams: { course_id: course.courseId },
    //   state: { origin: 'wishlist' } 
    // };
    console.log(course.courseId)
    if (puid) {
      this.router.navigate(['/university-detail', course.courseId, puid]);
    } else {
      this.router.navigate(['/university-detail', course.courseId]);
    }
  }
  
}


