<div class="counsellor-modal" *ngIf="isModalOpen">
    <div class="modal-content">
      <div class="modal-header">
        <span class="modal-title">
          <img src="assets/icons/phone.svg" alt="Phone Icon" class="phone-icon" />
          Connect to Counsellor
        </span>
        <button class="close-btn" (click)="closeModal()">×</button>
      </div>
      <div class="modal-body">
        <label for="date" class="form-label">Choose a date and time slot</label>
            <div class="flex-main">
                <div class="form-group">
                <!-- Date Picker -->
                <input
                    type="date"
                    id="date"
                    [(ngModel)]="selectedDate"
                    class="form-control date-picker"
                />
                </div>
        
                <div class="form-group">
                <!-- Time Picker -->
                    <select
                        id="timeSlot"
                        [(ngModel)]="selectedTimeSlot"
                        class="form-control time-slot-dropdown"
                    >
                        <option value="" disabled selected>-- Select a Time Slot --</option>
                        <option *ngFor="let slot of timeSlots" [value]="slot">{{ slot }}</option>
                    </select>
                </div>
            </div>
      </div>
      <div class="modal-footer">
        <button class="book-call-btn rounded-lg" (click)="bookCounsellorCall()">Book a Call</button>
      </div>
    </div>
</div>

<div class="success-modal" *ngIf="showSuccessModal">
  <div class="success-content">
    <span class="close-success" (click)="closeSuccessModal()">×</span>
    <div class="success-icon">
      <img src="https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1734083372607Tick%201.png" alt="Success" class="mt-1" />
    </div>
    <h2 class="font-poppins call-book">Call booked Successfully</h2>
    <p class="font-poppins call-statement">You will get a call from our counsellor at the chosen time. Please make sure to pick the call.</p>
  </div>
</div>
  